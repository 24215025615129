import React, { Component } from 'react';
import { MDBSelect, MDBContainer } from 'mdbreact';

class Gender extends Component {
	state = {
		options: [
			{
				value: 'male',
				text: 'MALE'
			},
			{
				value: 'female',
				text: 'FEMALE'
			}
		]
	};

	onChange = (ev) => {
		console.log(ev);
	};

	render() {
		return (
			<MDBContainer>
				<h6 style={{ fontWeight: 'bold', color: 'grey' }}>GENDER</h6>
				<MDBSelect
					options={this.state.options}
					getValue={this.props.onChange}
					// selected="Choose your option"
					label="Decline to answer"
					selectAll
				/>
			</MDBContainer>
		);
	}
}

export default Gender;
