import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';
import './Main.css';
import Nav from '../Nav';
import Footer from '../Footer';
import Features from './FeaturesPage';
import Carousel from './CarouselPage';
import Map from './Map';
import Testimonials from './TestimonialsMultiPage';
import Mission from './Mission';
import * as authActions from '../../../store/actions/authAction';

const Main = (props) => {
	const { onTryAutoSignup } = props;
	useEffect(
		() => {
			onTryAutoSignup();
		},
		[ onTryAutoSignup ]
	);
	const dynamicLeftPadding = {
		// paddingLeft: windowWidth > breakWidth ? '240px' : '0'
	};
	return (
		<React.Fragment>
			<div id="main">
				<Nav {...props} />
				<section id="home" style={{ dynamicLeftPadding, margin: '3rem 0% 0rem' }}>
					<Carousel id="carousel" fixed />
				</section>
				<MDBContainer>
					<section id="testimonials">
						<Testimonials />
					</section>
					<hr className="my-5" />
					<section id="Features">
						<Features
							style={{
								margin: '0rem 6% 0rem'
							}}
						/>
					</section>
					<hr className="my-5" />
					<section id="mission">
						<Mission
							style={{
								margin: '0rem 6% 0rem'
							}}
						/>
					</section>
					<hr className="my-5" />

					<section id="map">
						<Map />
					</section>
				</MDBContainer>
				<Footer style={{ ...dynamicLeftPadding, width: '100%', marginTop: 50 }} className="d-none d-lg-block" />
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.token !== null
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onTryAutoSignup: () => dispatch(authActions.authCheckState())
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
