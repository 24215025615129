import React from 'react';
import { MDBContainer } from 'mdbreact';
import classNames from 'classnames';

const SectionContainer = ({
	children,
	className,
	dark,
	description,
	header,
	noBorder,
	noBottom,
	style,
	title,
	flexCenter,
	flexCenterVert,
	flexColumn,
	label
}) => {
	const classes = classNames(
		'section',
		!noBottom && 'mb-5',
		!noBorder ? 'border p-3' : 'px-0',
		dark && 'grey darken-3',
		flexCenter && 'd-flex justify-content-center align-items-center',
		flexCenterVert && 'd-flex align-items-center',
		flexColumn && 'flex-column',
		className
	);
	description = description ? <p>{description}</p> : '';
	title = title ? <h2 className="mb-3">{title}</h2> : '';
	header = header ? <h4 className="mb-2">{header}</h4> : '';
	label = label ? <p className="mb-2">{label}</p> : '';

	return (
		<React.Fragment>
			{title}
			{header}
			{label}
			<MDBContainer fluid className={classes} style={style}>
				{description}
				{children}
			</MDBContainer>
		</React.Fragment>
	);
};

export default SectionContainer;
