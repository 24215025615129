import moment from 'moment';
export const ADD_ORDER = 'ADD_ORDER';
export const SET_ORDERS = 'SET_ORDERS';

export const fetchOrders = () => {
    return async (dispatch) => {
        // let url = 'http://10.0.0.11:5000/dme/orders/';
        let url = process.env.REACT_APP_API_ENDPOINT + '/dme/orders/';
        // let url = 'https://mhhc.herokuapp.com/dme/orders/';
        let config = {
            headers: {
                Accept: 'application/vnd.heroku+json; version=3'
            }
        };
        try {
            //TODO connect redux and userinfo to the order
            const response = await fetch(url, { config });

            if (!response.ok) {
                throw new Error('Something went wrong!');
            }

            const resData = await response.json();
            // console.log('resData', resData);

            const loadedOrders = [];

            for (const key in resData) {
                let roughDate = new Date(resData[key].OrderDate);
                loadedOrders.push(
                    // new Order(key, resData[key].cartItems, resData[key].totalAmount, new Date(resData[key].date))

                    {
                        key,
                        id: resData[key]._id,
                        userId: resData[key].userId,
                        items: resData[key].CartItems,
                        totalAmount: parseFloat(resData[key].TotalAmount),
                        date: moment(roughDate).format('MM DD YYYY')

                        // new Date(resData[key].OrderDate)
                    }
                );
            }
            // console.log('loadedOrders', loadedOrders);
            dispatch({ type: SET_ORDERS, orders: loadedOrders });
        } catch (err) {
            throw err;
        }
    };
};
