import React from 'react';
import Nav from '../Nav';
import Footer from '../Footer';
import Certificate from '../../../assets/images/certificate.jpg';
import { MDBRow, MDBCol, MDBIcon, MDBJumbotron, MDBContainer } from 'mdbreact';
const PcaCertification = (props) => {
    return (
        <div>
            <Nav />
            <MDBContainer>
                <main
                    style={{
                        margin: '10rem 6% 10rem'
                    }}
                >
                    <MDBJumbotron style={{ padding: 10, borderRadius: 15 }}>
                        <section className='my-5'>
                            <h2 className='h1-responsive font-weight-bold text-center my-5'>PCA Certification</h2>
                            <p
                                className='lead grey-text w-responsive text-center mx-auto mb-5'
                                style={{ fontWeight: 'bold' }}
                            >
                                Minnesota Department of Human Services (DHS)
                                <br />
                                PCA/CFSS Workers Training Certificate
                            </p>

                            <MDBRow>
                                <MDBCol lg='5' className='text-center text-lg-left'>
                                    <img
                                        // className='img-fluid'
                                        className='img-fluid z-depth-1 rounded-circle'
                                        alt=''
                                        src={Certificate}
                                        // style={{}}
                                    />
                                </MDBCol>
                                <MDBCol lg='7'>
                                    <MDBRow className='mb-3'>
                                        <MDBCol size='1'>
                                            <MDBIcon icon='share' size='lg' className='indigo-text' />
                                        </MDBCol>
                                        <MDBCol xl='10' md='11' size='10'>
                                            <h5 className='font-weight-bold mb-3' style={{ fontSize: 20 }}>
                                                Certification TEST:
                                            </h5>
                                            <p className='grey-text' style={{ fontWeight: 'bold' }}>
                                                Minnesota statute requires all PCAs to take and pass a certification
                                                test. The online course and test is free. PCAs must register with DHS
                                                before taking the certification test at:
                                                <a
                                                    href='https://registrations.dhs.state.mn.us/videoConf/Default.aspx?BusinessUnitID=16'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    Personal Care Assistance (PCA) and Community First Services and
                                                    Supports (CFSS) Training and Test.
                                                </a>
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className='mb-3'>
                                        <MDBCol size='1'>
                                            <MDBIcon icon='share' size='lg' className='indigo-text' />
                                        </MDBCol>
                                        <MDBCol xl='10' md='11' size='10'>
                                            <h5 className='font-weight-bold mb-3' style={{ fontSize: 20 }}>
                                                QUESTIONS:
                                            </h5>
                                            <p className='grey-text' style={{ fontWeight: 'bold' }}>
                                                Please call the DHS client help line with any questions you may have
                                                about the training at
                                                <a href='tel:(651) 431-4300'> (651) 431-4300</a> or email{' '}
                                                <a
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href='mailto:dsd.responsecenter@state.mn.us'
                                                >
                                                    dsd.responsecenter@state.mn.us
                                                </a>
                                                .
                                            </p>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className='mb-3'>
                                        <MDBCol size='1'>
                                            <MDBIcon icon='share' size='lg' className='indigo-text' />
                                        </MDBCol>
                                        <MDBCol xl='10' md='11' size='10'>
                                            <h5 className='font-weight-bold mb-3' style={{ fontSize: 20 }}>
                                                ON-COMPLETION:
                                            </h5>
                                            <p className='grey-text' style={{ fontWeight: 'bold' }}>
                                                Once the PCA has passed the training, please fax a copy of the
                                                certification to our Human Resources Department at fax (612) 343-3267 or
                                                email <a href='mailto:adunigan@mhhcare.com'>adunigan@mhhcare.com</a> and
                                                contact them to send out an application for the PCA at {' '}
                                                <a href='tel:(612) 343-3265'>(612) 343-3265</a>
                                            </p>
                                            .
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </section>
                    </MDBJumbotron>
                </main>
            </MDBContainer>
            <Footer style={{ width: '100%', position: 'fixed' }} className='d-none d-lg-block' />
        </div>
    );
};

export default PcaCertification;
