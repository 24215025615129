import React, { Component } from 'react';
import { MDBCollapse, MDBCard, MDBCardBody, MDBCollapseHeader, MDBIcon } from 'mdbreact';

class CollapsePage extends Component {
	state = {
		collapseID: ''
	};

	toggleCollapse = (collapseID) => () =>
		this.setState((prevState) => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : ''
		}));

	render() {
		return (
			<div className="accordion md-accordion accordion-3 z-depth-1-half">
				<MDBCard>
					<MDBCollapseHeader
						onClick={this.toggleCollapse('collapse1')}
						tag="h3"
						tagClassName="blue-text d-flex justify-content-between align-items-center"
					>
						ACCEPTED MEDICIAD INSURANCE PLANS
						<MDBIcon
							icon={this.state.collapseID === 'collapse1' ? 'angle-up' : 'angle-down'}
							className="black-text"
							size="2x"
						/>
					</MDBCollapseHeader>
					<MDBCollapse id="collapse1" isOpen={this.state.collapseID}>
						<MDBCardBody className="pt-0">
							<h5>
								<ul>
									<li>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="https://www.bluecrossmn.com/"
										>
											Blue Cross Blue Shield
										</a>
									</li>
									<br />
									<li>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="https://www.healthpartners.com/"
										>
											Health Partners
										</a>
									</li>
									<br />
									<li>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="https://www.hennepinhealth.org/"
										>
											Hennepin Health
										</a>
									</li>
									<br />
									<li>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="https://mn.gov/dhs/people-we-serve/adults/health-care/health-care-programs/programs-and-services/medical-assistance.jsp"
										>
											M.A. (Medical Assistance)
										</a>
									</li>
									<br />
									<li>
										<a target="_blank" rel="noopener noreferrer" href="https://www.medica.com/">
											Medica
										</a>
									</li>
									<br />
									<li>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="https://home.ucare.org/en-us/"
										>
											UCare
										</a>
									</li>
								</ul>
							</h5>
						</MDBCardBody>
					</MDBCollapse>
				</MDBCard>
			</div>
		);
	}
}

export default CollapsePage;
