import React, { useState } from 'react';
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavItem,
    MDBNavLink,
    MDBNavbarToggler,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBIcon,
    MDBContainer
} from 'mdbreact';
import Logo from '../../assets/images/mhhcLogo2.png';
import * as authActions from '../../store/actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
const NavbarPage = (props) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.token !== null);
    const Username = useSelector((state) => state.auth.username);
    const role = useSelector((state) => state.auth.role);
    const onSetAuthRedirectPath = (path) => dispatch(authActions.setAuthRedirectPath(path));
    const dashboard = () => {
        if (isAuthenticated && role === 'superadmin') dispatch(onSetAuthRedirectPath('/adminHome'));
        props.history.push('/adminHome');
    };

    return (
        <MDBContainer>
            <MDBNavbar
                dark
                expand='lg'
                style={{ backgroundColor: 'rgba(20,82,148,1)', padding: '0px 0px 0px 0px' }}
                fixed='top'
                scrolling
                transparent
            >
                <MDBNavbarBrand>
                    <a href='/'>
                        <img
                            src={Logo}
                            alt=''
                            style={{
                                marginLeft: '10px',
                                marginRight: '0px',
                                height: '2.5rem',
                                width: '14rem'
                            }}
                        />
                    </a>
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={toggle} style={{ marginRight: '10px' }} />
                <MDBCollapse id='navbarCollapse3' isOpen={isOpen} navbar style={{ marginRight: '20px' }}>
                    <MDBNavbarNav right style={{ fontsize: '10px' }}>
                        <MDBNavItem>
                            <MDBNavLink className='waves-effect waves-light' to='/'>
                                <MDBIcon className='mr-1' icon='home' />
                                <span className=' d-md-inline ml-1'>HOME</span>
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBDropdown>
                                <MDBDropdownToggle nav caret>
                                    <MDBIcon className='mr-1' icon='briefcase' />
                                    <span className=' d-md-inline'>ABOUT</span>
                                </MDBDropdownToggle>
                                <MDBDropdownMenu basic style={{ minWidth: '200px' }}>
                                    <MDBDropdownItem href='/about'>OVERVIEW</MDBDropdownItem>
                                    <MDBDropdownItem href='/services'>SERVICES</MDBDropdownItem>
                                    <MDBDropdownItem href='/insurance'>INSURANCE</MDBDropdownItem>
                                    <MDBDropdownItem href='/staff'>THE TEAM</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBDropdown>
                                <MDBDropdownToggle nav caret>
                                    <MDBIcon className='mr-1' icon='tools' />
                                    <span className=' d-md-inline'>RESOURCES</span>
                                </MDBDropdownToggle>
                                <MDBDropdownMenu basic style={{ minWidth: '200px' }}>
                                    <MDBDropdownItem href='/timeSheetFiles'>TIMESHEETS</MDBDropdownItem>
                                    <MDBDropdownItem href='/pca-certification'>PCA CERTIFICATION</MDBDropdownItem>
                                    <MDBDropdownItem href='/coming-soon'>EMPLOYEE NEWS</MDBDropdownItem>
                                    {/* <MDBDropdownItem href="/employee-news">EMPLOYEE NEWS</MDBDropdownItem> */}
                                    <MDBDropdownItem href='/coming-soon'>FIND PCA</MDBDropdownItem>
                                    {/* <MDBDropdownItem href='/pca-finder'>FIND PCA</MDBDropdownItem> */}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBNavItem>

                        <MDBNavItem>
                            <MDBDropdown>
                                <MDBDropdownToggle nav caret className='dopdown-toggle'>
                                    <MDBIcon className='mr-1' icon='briefcase' />
                                    <span className=' d-md-inline'>CAREERS</span>
                                </MDBDropdownToggle>
                                <MDBDropdownMenu basic style={{ minWidth: '200px' }}>
                                    {/* <MDBDropdownItem href='/careers'>FIND A CAREER</MDBDropdownItem> */}
                                    <MDBDropdownItem href='/coming-soon'>FIND A CAREER</MDBDropdownItem>
                                    <MDBDropdownItem href='/coming-soon'>JOB DASHBOARD</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink to='Contact'>
                                <MDBIcon className='mr-1' icon='comments' />
                                <span className=' d-md-inline ml-1'>CONTACT</span>
                            </MDBNavLink>
                        </MDBNavItem>

                        <MDBNavItem>
                            {!isAuthenticated ? (
                                <MDBNavLink to='/login'>
                                    <MDBIcon className='mr-1' icon='lock' />
                                    Login
                                </MDBNavLink>
                            ) : (
                                <MDBDropdown>
                                    <MDBDropdownToggle nav caret>
                                        <MDBIcon icon='unlock-alt' />
                                        <span className='d-none d-md-inline'>{Username}</span>
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu basic style={{ minWidth: '200px' }}>
                                        <MDBDropdownItem href='/'>Home</MDBDropdownItem>
                                        {isAuthenticated && role === 'superadmin' ? (
                                            <MDBDropdownItem
                                                onClick={() => {
                                                    dashboard();
                                                }}
                                            >
                                                DASHBOARD
                                            </MDBDropdownItem>
                                        ) : (
                                            <MDBDropdownItem href='/'>Job Dashboard</MDBDropdownItem>
                                        )}

                                        <MDBDropdownItem
                                            onClick={() => {
                                                dispatch(authActions.logout());
                                            }}
                                        >
                                            Log Out
                                        </MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            )}
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
        </MDBContainer>
    );
};

export default NavbarPage;
