import React, { Component } from 'react';
import {
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    MDBTooltip,
    MDBView,
    MDBBtn,
    MDBPagination,
    MDBPageItem,
    MDBPageNav,
    MDBCardHeader,
    MDBListGroup,
    MDBListGroupItem,
    MDBTable,
    MDBBtnFixed,
    MDBBtnFixedItem
} from 'mdbreact';

class adminHome extends Component {
    state = {
        buttonStyle: {
            transform: 'scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)',
            opacity: '0'
        }
    };

    onHover = () => {
        this.setState({
            buttonStyle: {
                transform: 'scaleY(1) scaleX(1) translateY(0) translateX(0)',
                opacity: '1'
            }
        });
    };

    onMouseLeave = () => {
        this.setState({
            buttonStyle: {
                transform: 'scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)',
                opacity: '0'
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <section className='my-4'>
                    <MDBCard cascade narrow>
                        <section>
                            <MDBView
                                cascade
                                className='gradient-card-header blue-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center'
                            >
                                <div className='text-left'>
                                    <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                        <MDBIcon icon='th-large' className='mt-0' />
                                    </MDBBtn>
                                    <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                        <MDBIcon icon='columns' className='mt-0' />
                                    </MDBBtn>
                                </div>

                                <a href='#!' className='white-text mx-3'>
                                    JOB CANDIDATE PREVIEW
                                </a>

                                <div className='text-right'>
                                    <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                        <MDBIcon icon='pencil-alt' className='mt-0' />
                                    </MDBBtn>
                                    <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                        <MDBIcon icon='times' className='mt-0' />
                                    </MDBBtn>
                                    <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                        <MDBIcon icon='info-circle' className='mt-0' />
                                    </MDBBtn>
                                </div>
                            </MDBView>
                            <div className='p-2 mx-4 mb-5' style={{ border: '1px solid #e0e0e9' }}>
                                <MDBRow>
                                    <MDBCol xl='3' lg='6' md='12'>
                                        <MDBSelect style={{ margin: 0 }}>
                                            <MDBSelectInput selected='Bulk actions' />
                                            <MDBSelectOptions>
                                                <MDBSelectOption disabled>Bulk actions</MDBSelectOption>
                                                <MDBSelectOption value='1'>Delete</MDBSelectOption>
                                                <MDBSelectOption value='2'>Export</MDBSelectOption>
                                                <MDBSelectOption value='3'>Change segment</MDBSelectOption>
                                            </MDBSelectOptions>
                                        </MDBSelect>
                                    </MDBCol>

                                    <MDBCol xl='3' lg='6' md='12'>
                                        <MDBSelect style={{ margin: 0 }}>
                                            <MDBSelectInput selected='Show only' />
                                            <MDBSelectOptions>
                                                <MDBSelectOption disabled>Show only</MDBSelectOption>
                                                <MDBSelectOption value='1'>All (2000)</MDBSelectOption>
                                                <MDBSelectOption value='2'>Never opened (200)</MDBSelectOption>
                                                <MDBSelectOption value='3'>
                                                    Opened but unanswered (1600)
                                                </MDBSelectOption>
                                                <MDBSelectOption value='4'>Answered (200)</MDBSelectOption>
                                                <MDBSelectOption value='5'>Unsubscribed (50)</MDBSelectOption>
                                            </MDBSelectOptions>
                                        </MDBSelect>
                                    </MDBCol>
                                    <MDBCol xl='3' lg='6' md='12'>
                                        <MDBSelect style={{ margin: 0 }}>
                                            <MDBSelectInput selected='Filter segments' />
                                            <MDBSelectOptions>
                                                <MDBSelectOption disabled>Filter segments</MDBSelectOption>
                                                <MDBSelectOption value='1'>
                                                    Contacts in no segments <span> (100)</span>
                                                </MDBSelectOption>
                                                <MDBSelectOption value='2'>
                                                    Segment 1 <span> (2000)</span>
                                                </MDBSelectOption>
                                                <MDBSelectOption value='3'>
                                                    Segment 2 <span> (1000)</span>
                                                </MDBSelectOption>
                                                <MDBSelectOption value='4'>
                                                    Segment 3 <span> (4000)</span>
                                                </MDBSelectOption>
                                            </MDBSelectOptions>
                                        </MDBSelect>
                                    </MDBCol>
                                    <MDBCol xl='3' lg='6' md='12' style={{ display: 'flex' }}>
                                        <form className='form-inline ml-2'>
                                            <div className='form-group md-form py-0 mt-0'>
                                                <input className='form-control w-80' type='text' placeholder='Search' />
                                                <MDBBtn size='sm' color='primary' className='d-inline ml-2 px-2'>
                                                    <MDBIcon icon='search' />
                                                </MDBBtn>
                                            </div>
                                        </form>
                                    </MDBCol>
                                </MDBRow>
                            </div>

                            <MDBCard narrow className='z-depth-0'>
                                <div className='px-4'>
                                    <MDBTable hover responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input className='form-check-input' type='checkbox' id='checkbox' />
                                                    <label
                                                        htmlFor='checkbox'
                                                        className='form-check-label mr-2 label-table'
                                                    />
                                                </th>
                                                <th className='th-lg'>
                                                    First Name
                                                    <MDBIcon icon='sort' className='ml-1' />
                                                </th>
                                                <th className='th-lg'>
                                                    Last Name
                                                    <MDBIcon icon='sort' className='ml-1' />
                                                </th>
                                                {/* <th className='th-lg'>
                                                    Username
                                                    <MDBIcon icon='sort' className='ml-1' />
                                                </th> */}
                                                <th className='th-lg'>
                                                    Email
                                                    <MDBIcon icon='sort' className='ml-1' />
                                                </th>
                                                <th className='th-lg'>
                                                    Address
                                                    <MDBIcon icon='sort' className='ml-1' />
                                                </th>
                                                <th className='th-lg'>
                                                    City
                                                    <MDBIcon icon='sort' className='ml-1' />
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <th scope='row'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        id='checkbox1'
                                                    />
                                                    <label htmlFor='checkbox1' className='label-table' />
                                                </th>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                {/* <td>@mdo</td> */}
                                                <td>markotto@gmail.com</td>
                                                <td>USA</td>
                                                <td>San Francisco</td>
                                            </tr>
                                            <tr>
                                                <th scope='row'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        id='checkbox2'
                                                    />
                                                    <label htmlFor='checkbox2' className='label-table' />
                                                </th>
                                                <td>Jacob</td>
                                                <td>Thornton</td>
                                                {/* <td>@fat</td> */}
                                                <td>jacobt@gmail.com</td>
                                                <td>France</td>
                                                <td>Paris</td>
                                            </tr>
                                            <tr>
                                                <th scope='row'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        id='checkbox3'
                                                    />
                                                    <label htmlFor='checkbox3' className='label-table' />
                                                </th>
                                                <td>Larry</td>
                                                <td>Bird</td>
                                                {/* <td>@twitter</td> */}
                                                <td>larrybird@gmail.com</td>
                                                <td>Germany</td>
                                                <td>Berlin</td>
                                            </tr>
                                            <tr>
                                                <th scope='row'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        id='checkbox4'
                                                    />
                                                    <label htmlFor='checkbox4' className='label-table' />
                                                </th>
                                                <td>Paul</td>
                                                <td>Topolski</td>
                                                {/* <td>@P_Topolski</td> */}
                                                <td>ptopolski@gmail.com</td>
                                                <td>Poland</td>
                                                <td>Warsaw</td>
                                            </tr>
                                            <tr>
                                                <th scope='row'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        id='checkbox5'
                                                    />
                                                    <label htmlFor='checkbox5' className='label-table' />
                                                </th>
                                                <td>Anna</td>
                                                <td>Doe</td>
                                                {/* <td>@andy</td> */}
                                                <td>annadoe@gmail.com</td>
                                                <td>Spain</td>
                                                <td>Madrid</td>
                                            </tr>
                                        </tbody>
                                    </MDBTable>
                                    <hr className='my-0' />
                                    <MDBSelect className='colorful-select w-10 float-left dropdown-primary mt-2 hidden-md-down'>
                                        <MDBSelectInput selected='Rows number' />
                                        <MDBSelectOptions>
                                            <MDBSelectOption disabled>Rows number</MDBSelectOption>
                                            <MDBSelectOption value='1'>5 rows</MDBSelectOption>
                                            <MDBSelectOption value='2'>25 rows</MDBSelectOption>
                                            <MDBSelectOption value='3'>50 rows</MDBSelectOption>
                                            <MDBSelectOption value='4'>100 rows</MDBSelectOption>
                                        </MDBSelectOptions>
                                    </MDBSelect>

                                    <MDBPagination circle className='my-4 float-right'>
                                        <li className='page-item disabled clearfix d-none d-md-block'>
                                            <a className='page-link' href='#!'>
                                                First
                                            </a>
                                        </li>
                                        <MDBPageItem disabled>
                                            <MDBPageNav className='page-link' aria-label='Previous'>
                                                <span aria-hidden='true'>&laquo;</span>
                                                <span className='sr-only'>Previous</span>
                                            </MDBPageNav>
                                        </MDBPageItem>
                                        <MDBPageItem active>
                                            <MDBPageNav className='page-link'>
                                                1 <span className='sr-only'>(current)</span>
                                            </MDBPageNav>
                                        </MDBPageItem>
                                        <MDBPageItem>
                                            <MDBPageNav className='page-link'>2</MDBPageNav>
                                        </MDBPageItem>
                                        <MDBPageItem>
                                            <MDBPageNav className='page-link'>3</MDBPageNav>
                                        </MDBPageItem>
                                        <MDBPageItem>
                                            <MDBPageNav className='page-link'>4</MDBPageNav>
                                        </MDBPageItem>
                                        <MDBPageItem>
                                            <MDBPageNav className='page-link'>5</MDBPageNav>
                                        </MDBPageItem>
                                        <MDBPageItem>
                                            <MDBPageNav className='page-link' aria-label='Next'>
                                                <span aria-hidden='true'>&raquo;</span>
                                                <span className='sr-only'>Next</span>
                                            </MDBPageNav>
                                        </MDBPageItem>
                                    </MDBPagination>
                                </div>
                            </MDBCard>
                        </section>
                    </MDBCard>
                </section>

                <section className='mb-4'>
                    <MDBRow>
                        <MDBCol lg='4' md='12' className='my-4'>
                            <MDBCard>
                                <MDBCardHeader color='primary-color' className='white-text'>
                                    Things to improve
                                </MDBCardHeader>
                                <MDBCardBody className='pt-0 px-1'>
                                    <MDBCardBody className='text-center'>
                                        <MDBListGroup>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Employee Safety &amp; Fulfillment
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Customer Reviews on Service
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Internet Presence
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Customer Obsession
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Claims Process
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                        </MDBListGroup>
                                    </MDBCardBody>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>

                        <MDBCol lg='4' md='12' className='my-4'>
                            <MDBCard>
                                <MDBCardHeader color='primary-color' className='white-text'>
                                    Tasks to do
                                </MDBCardHeader>
                                <MDBCardBody className='pt-0 px-1'>
                                    <MDBCardBody className='text-center'>
                                        <MDBListGroup>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Employee FeedBack
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Employee Input in Hiring
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Automate Onboarding to claims Process
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Company Retreat
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Goals based Managment
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                        </MDBListGroup>
                                    </MDBCardBody>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>

                        <MDBCol lg='4' md='12' className='my-4'>
                            <MDBCard>
                                <MDBCardHeader color='primary-color' className='white-text'>
                                    Statisctics
                                </MDBCardHeader>
                                <MDBCardBody className='pt-0 px-1'>
                                    <MDBCardBody className='text-center'>
                                        <MDBListGroup>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Claim Denials
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Customer Review
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Conversion Rate
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Customer Aquisition
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem
                                                hover
                                                className='d-flex justify-content-between dark-grey-text'
                                            >
                                                Refferal Sources
                                                <MDBTooltip domElement placement='top'>
                                                    <span>
                                                        <MDBIcon icon='wrench' className='ml-auto' />
                                                    </span>
                                                    <div>Click to fix</div>
                                                </MDBTooltip>
                                            </MDBListGroupItem>
                                        </MDBListGroup>
                                    </MDBCardBody>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </section>
                <MDBBtnFixed
                    onMouseEnter={this.onHover}
                    onMouseLeave={this.onMouseLeave}
                    floating
                    color='red'
                    icon='pencil-alt'
                    style={{ bottom: '100px', right: '24px' }}
                >
                    <MDBBtnFixedItem buttonStyle={this.state.buttonStyle} color='red' icon='star' />
                    <MDBBtnFixedItem buttonStyle={this.state.buttonStyle} color='yellow' icon='user' />
                    <MDBBtnFixedItem buttonStyle={this.state.buttonStyle} color='green' icon='envelope' />
                    <MDBBtnFixedItem
                        buttonStyle={this.state.buttonStyle}
                        color='blue'
                        icon='shopping-cart'
                        onClick={this.handleClick}
                    />
                </MDBBtnFixed>
            </React.Fragment>
        );
    }
}

export default adminHome;
