import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBMask, MDBView } from 'mdbreact';
import './TimeSheetFiles.css';
import Nav from '../Nav';
import Footer from '../Footer';
// import PDF from '../../../assets/images/pdf.svg';
import Explantion from './Explanation';
import TimeSheetTable from './TimeSheetTable';

class TimeSheetFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseID: '',
            photoIndex: 0,
            isOpen: false
        };
    }

    toggleCollapse = (collapseID) => () =>
        this.setState((prevState) => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ''
        }));

    render() {
        return (
            <div>
                <Nav />
                <div id='posts'>
                    <section id='home'>
                        <MDBView>
                            <MDBMask
                                className='d-flex justify-content-center align-items-center'
                                overlay='stylish-strong'
                            >
                                <MDBContainer className='h-100 d-flex justify-content-center align-items-center'>
                                    <MDBRow>
                                        <MDBCol md='12' className='mt-5 mx-auto text-center'>
                                            <div className='text-center'>
                                                <h1 className='h1-reponsive white-text text-uppercase font-weight-bold mb-3'>
                                                    <strong>DownLoad TimeSheetFiles</strong>
                                                </h1>
                                                <hr className='hr-light mt-4 wow fadeInDown' />
                                                <h5 className='text-uppercase mb-5 white-text wow fadeInDown'>
                                                    <strong>Ease &amp; Effieceny </strong>
                                                </h5>
                                                <a
                                                    href='#!'
                                                    className='btn btn-outline-white wow fadeInDown waves-effect waves-light'
                                                >
                                                    PCA Section
                                                </a>
                                                <a
                                                    href='#!'
                                                    className='btn btn-outline-white wow fadeInDown waves-effect waves-light'
                                                >
                                                    Home MAking Section
                                                </a>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBMask>
                        </MDBView>
                    </section>
                    <main
                        style={{
                            margin: '10rem 6% 10rem'
                        }}
                    >
                        <MDBContainer>
                            <div>
                                <section id='recent' className=' my-5 text-center text-lg-left'>
                                    <Explantion />
                                </section>
                                <section id='older' className='section extra-margins text-center'>
                                    <h2
                                        className='text-center  h1'
                                        style={{ color: '#B9B9B9', fontWeight: 'bold', margin: 0, padding: 0 }}
                                    >
                                        TIMESHEET FILES:
                                    </h2>

                                    <TimeSheetTable style={{ margin: 10 }} />
                                </section>
                            </div>
                        </MDBContainer>
                    </main>
                </div>
                <Footer style={{ position: 'fixed', width: '100%' }} className='d-none d-lg-block' />
            </div>
        );
    }
}

export default TimeSheetFiles;
