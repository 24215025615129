import React, { Component } from 'react';
import MDBFileupload from 'mdb-react-fileupload';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import './Upload.css';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import { MDBCard, MDBCardBody, MDBIcon, MDBBtn } from 'mdbreact';

class formExtendedExample extends Component {
    constructor() {
        super();
        this.state = {
            selectedFile: null,
            files: [],
            description: '',
            startDate: new Date(),
            payrollDates: '',
            selectedOption: '',
            redirectTo: null
        };
        this.handleSelectorChange = this.handleSelectorChange.bind(this);
        this.descriptionChangedHandler = this.descriptionChangedHandler.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.handleFileuploadChange = this.handleFileuploadChange.bind(this);
    }

    handleSelectorChange = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    };
    componentDidMount() {
        this.setState({
            redirectTo: null
        });
    }

    descriptionChangedHandler = (event) => {
        this.setState({ description: event.target.value });
    };

    handleChange = (value) => {
        let x = moment(value).format('MM-DD-YYYY');
        this.setState({
            startDate: value,
            payrollDates: x
        });
    };

    onSubmitHandler = (event) => {
        event.preventDefault();
        let formData = new FormData();
        formData.append('UploadDate', this.state.payrollDates);
        formData.append('UploadDescription', this.state.description);
        formData.append('FileType', this.state.selectedOption.value);
        let PCAFileType = this.state.selectedOption.value;
        formData.append('files', this.state.selectedFile);
        let headers = {
            Type: 'formData',
            UploadDate: this.state.payrollDates,
            UploadDescription: this.state.description,
            FileType: PCAFileType
        };

        console.log('formData', formData);
        console.log('headers', headers);
        let url = process.env.REACT_APP_API_ENDPOINT + '/files/upload';
        // .post('https://mhhc-server.herokuapp.com/files/upload', formData, { headers: headers })
        axios
            .post(url, formData, { headers: headers })
            .then((response) => {
                // window.location.href = window.location.href + 'allfiles';
                if (response.status === 200) {
                    alert('Form submitted!');
                    this.setState({
                        redirectTo: '/adminHome',
                        selectedFile: null,
                        description: '',
                        startDate: new Date(),
                        payrollDates: '',
                        selectedOption: ''
                    });
                    this.fileupload.resetPreview();
                }
            })
            .catch((err) => {
                this.fileupload.resetPreview();
                console.log(err);
            });
    };

    handleFileuploadChange = (file) => {
        this.setState({
            selectedFile: file
        });
    };

    render() {
        const options = [
            { value: 'PCA Timesheets', label: 'PCA Timesheets' },
            { value: 'HOMEMAKING Timesheets', label: 'HOMEMAKING Timesheets' }
        ];
        return (
            <MDBCard className='px-3 grey-text' style={{ borderRadius: 10 }}>
                <MDBCardBody>
                    <h1>{this.state.loaded}</h1>
                    <h3 className='text-center mb-5 mt-4 font-weight-bold'>
                        <strong>Timesheets </strong>
                        <strong className='light-green-text font-weight-bold'>Uploads</strong>
                    </h3>
                    <form onSubmit={this.onSubmitHandler}>
                        <p style={{ fontWeight: 'bold', marginBottom: 0, color: 'black' }}>Uploader:</p>
                        <MDBFileupload
                            getValue={this.handleFileuploadChange.bind(this)}
                            maxFileSize='5M'
                            ref={(fileupload) => (this.fileupload = fileupload)}
                        />
                        <div className='mb-4'>
                            <div className='customDatePickerWidth' style={{ marginBottom: 20 }}>
                                <p style={{ fontWeight: 'bold', marginBottom: 0, color: 'black' }}>PayrollDate:</p>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleChange}
                                    id='DatePicker'
                                />
                            </div>
                            <div style={{ marginBottom: 20 }}>
                                <p style={{ fontWeight: 'bold', marginBottom: 0, color: 'black' }}>FileType:</p>
                                <Select
                                    value={this.state.selectedOption}
                                    onChange={this.handleSelectorChange}
                                    options={options}
                                />
                            </div>

                            <div className='input-group'>
                                <div className='input-group-prepend'>
                                    <span className='input-group-text' id='basic-addon'>
                                        <i className='fas fa-pencil-alt prefix' />
                                    </span>
                                </div>
                                <textarea
                                    className='form-control'
                                    id='exampleFormControlTextarea1'
                                    placeholder='Describe'
                                    rows='2'
                                    onChange={this.descriptionChangedHandler}
                                />
                            </div>
                        </div>
                        <div className='form-check my-4' />
                        <div className='text-right mt-4'>
                            <MDBBtn type='submit' color='success'>
                                Send<MDBIcon far icon='paper-plane' className='ml-2' />
                            </MDBBtn>
                        </div>
                    </form>
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default formExtendedExample;

// "heroku-postbuild": "NPM_CONFIG_PRODUCTION=false npm install --prefix client && npm run build --prefix client"
