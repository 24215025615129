import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import Nav from '../components/pages/Nav';
import ComingSoon from './components/ComingSoon';
import Footer from '../components/pages/Footer';
import SectionContainer from '../components/sectionContainer';
import car from './images/floating-cogs.svg';
import './styles/Csf.css';
const index = (props) => {
	const dynamicLeftPadding = {};
	return (
		<div id="cspage">
			<Nav />
			<MDBContainer style={{ marginTop: 80 }} id="container">
				<MDBRow className="py-3">
					<MDBCol md="12">
						<SectionContainer noBorder>
							<MDBCard
								style={{
									marginBottom: '0px',
									border: '5px solid rgba(20,82,148,1)',
									borderRadius: 10,
									backgroundColor: 'rgb(105,105,105)',
									paddingLeft: 15,
									backgroundImage: `url(${car})`
								}}
							>
								<MDBCardBody>
									<ComingSoon />
								</MDBCardBody>
							</MDBCard>
						</SectionContainer>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
			<Footer style={{ ...dynamicLeftPadding, position: 'fixed', width: '100%' }} className="d-none d-lg-block" />
		</div>
	);
};

export default index;
