import React, { Component } from 'react';
import './CareerPage.css';
import Nav from '../Nav';
import Footer from '../Footer';
import JobsTable from './JobsTable';

class Careers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseID: '',
            windowWidth: 0,
            breakWidth: 1400
        };
    }
    getValueOfSelectOne = (value) => {
        console.log(value);
    };

    toggleCollapse = (collapseID) => () =>
        this.setState((prevState) => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ''
        }));

    render() {
        const dynamicLeftPadding = {
            paddingLeft: this.state.windowWidth > this.state.breakWidth ? '240px' : '0'
        };
        return (
            <div>
                <Nav />
                <main
                    style={{
                        dynamicLeftPadding,
                        margin: '0.1rem 0% 10rem'
                    }}
                >
                    {/* <br /> */}
                    <JobsTable {...this.props} />
                </main>

                <Footer
                    style={{ ...dynamicLeftPadding, position: 'fixed', width: '100%' }}
                    className='d-none d-lg-block'
                />
            </div>
        );
    }
}

export default Careers;
