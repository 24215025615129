import React from 'react';
import { MDBContainer } from 'mdbreact';
import './Service.css';
import Nav from '../Nav';
import CollapseInfo from './CollapsePage';
import Footer from '../Footer';

class Register extends React.Component {
    state = {
        collapseID: '',
        windowWidth: 0,
        breakWidth: 1400
    };

    toggleCollapse = (collapseID) => () =>
        this.setState((prevState) => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ''
        }));

    render() {
        const dynamicLeftPadding = {
            paddingLeft: this.state.windowWidth > this.state.breakWidth ? '240px' : '0'
        };
        return (
            <div id='register'>
                <Nav />

                <main style={{ margin: '8rem 0% 5rem' }}>
                    <MDBContainer>
                        <CollapseInfo />
                    </MDBContainer>
                </main>
                {/* <Footer
                    style={{ ...dynamicLeftPadding, width: '100%', position: 'fixed' }}
                    className='d-none d-lg-block'
                /> */}
                <Footer style={{ ...dynamicLeftPadding, width: '100%', marginTop: 50 }} className='d-none d-lg-block' />
            </div>
        );
    }
}

export default Register;
