import React from 'react';
import axios from 'axios';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBStepper,
    MDBStep,
    MDBBtn,
    MDBInput,
    MDBLink,
    MDBFileInput,
    MDBCard
} from 'mdbreact';
import Gender from './Gender';
import SectionContainer from '../../sectionContainer';
import Race from './Race';
import Nav from '../Nav';
import Footer from '../Footer';
import { connect } from 'react-redux';

class StepperExample extends React.Component {
    constructor(props) {
        super();
        this.state = {
            singleFile: null,
            formActivePanel1: 1,
            formActivePanel1Changed: false,
            firstName: '',
            SecondName: '',
            Email: '',
            Address: '',
            City: '',
            State: '',
            ZipCode: '',
            Contact: '',
            Gender: '',
            Race: '',
            redirectTo: null,
            userId: null,
            windowWidth: 0,
            breakWidth: 1400
        };
        this.swapFormActive = this.swapFormActive.bind(this);
        this.handleNextPrevClick = this.handleNextPrevClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleGender = this.handleGender.bind(this);
        this.handleRace = this.handleRace.bind(this);
        this.fileInputHandler = this.fileInputHandler.bind(this);
    }

    getValue = (value) => {
        console.log(value);
    };

    swapFormActive = (a) => (param) => (event) => {
        this.setState({
            ['formActivePanel' + a]: param,
            ['formActivePanel' + a + 'Changed']: true
        });
    };

    handleNextPrevClick = (a) => (param) => (event) => {
        console.log('===>A', a);
        console.log('===>E', event);
        console.log('==>param', param);
        this.setState({
            ['formActivePanel' + a]: param,
            ['formActivePanel' + a + 'Changed']: true
        });
    };

    handleSubmission = () => {
        alert('Form submitted!');
    };

    calculateAutofocus = (a) => {
        if (this.state['formActivePanel' + a + 'Changed']) {
            return true;
        }
    };

    componentDidMount() {
        this.setState(
            {
                // redirectTo: null
            }
        );
    }
    handleChange(event) {
        console.log(event.targetval);
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleGender(values) {
        console.log('!!!!', values);
        this.setState({
            Gender: values[0]
        });
    }
    handleRace(values) {
        console.log('!!!!', values);
        this.setState({
            Race: values[0]
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        console.log('handleSubmit');
        // const userName = localStorage.getItem('username');
        // const time = new Date().getTime();
        const uniq_Id = localStorage.getItem('userId');

        let formData = new FormData();
        formData.append('files', this.state.singleFile);
        formData.append('firstName', this.state.firstName);
        formData.append('SecondName', this.state.SecondName);
        formData.append('Email', this.state.Email);
        formData.append('Address', this.state.Address);
        formData.append('City', this.state.City);
        formData.append('State', this.state.State);
        formData.append('ZipCode', this.state.ZipCode);
        formData.append('Contact', this.state.Contact);
        formData.append('Gender', this.state.Gender);
        formData.append('Race', this.state.Race);
        formData.append('uid', uniq_Id);

        let headers = {
            Type: 'formData',
            uid: uniq_Id
        };

        axios
            .post(process.env.REACT_APP_API_ENDPOINT + '/applicationsForm/add', formData, { headers: headers })
            .then((response) => {
                console.log('jobs response: ');
                console.log(response);
                if (response.status === 200) {
                    alert('Form submitted!');
                    this.setState({
                        redirectTo: '/'
                    });
                }
            })
            .catch((error) => {
                console.log('jobs error: ');
                console.log(error);
                alert('Form Failed!');
            });
    }
    fileInputHandler(value) {
        console.log(value[0]);
        this.setState({
            singleFile: value[0]
        });
    }
    render() {
        const dynamicLeftPadding = {
            paddingLeft: this.state.windowWidth > this.state.breakWidth ? '240px' : '0'
        };
        return (
            <React.Fragment>
                <Nav />
                <MDBContainer>
                    <main
                        style={{
                            margin: '5rem 0% 6rem',
                            backgroundColor: 'rgba(20,82,148,1)'
                            // 'rgba(255,255,255, 0.9)'
                        }}
                    >
                        <section id='home'>
                            <MDBCard>
                                <MDBContainer>
                                    <h2 className='text-center font-weight-bold pt-4 pb-5 mb-2'>
                                        <strong>Registration form</strong>
                                    </h2>
                                    <MDBStepper icon>
                                        <MDBStep
                                            icon='info'
                                            stepName='Basic Information'
                                            onClick={this.swapFormActive(1)(1)}
                                        />
                                        <MDBStep
                                            far
                                            icon='folder-open'
                                            stepName='Personal Data'
                                            onClick={this.swapFormActive(1)(2)}
                                        />
                                        <MDBStep
                                            fab
                                            icon='wpforms'
                                            stepName='Terms and Conditions'
                                            onClick={this.swapFormActive(1)(3)}
                                        />
                                        <MDBStep icon='check' stepName='Finish' onClick={this.swapFormActive(1)(4)} />
                                    </MDBStepper>

                                    <form action='' method='post'>
                                        <MDBRow>
                                            {this.state.formActivePanel1 === 1 && (
                                                <MDBCol md='12'>
                                                    <h3 className='font-weight-bold pl-0 my-4'>
                                                        <strong>Basic Information</strong>
                                                    </h3>

                                                    <div className='input-group' style={{ marginBottom: '10px' }}>
                                                        <div className='input-group-prepend'>
                                                            <p
                                                                className='input-group-text'
                                                                id='basic-addon'
                                                                style={{ fontWeight: 'bold' }}
                                                            >
                                                                Last Name:
                                                            </p>
                                                        </div>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Last Name'
                                                            aria-label='LastName'
                                                            aria-describedby='basic-addon'
                                                            type='SecondName'
                                                            label='LastName'
                                                            icon='user-alt'
                                                            group='true'
                                                            name='SecondName'
                                                            value={this.state.SecondName}
                                                            onChange={this.handleChange}
                                                            autoFocus={this.calculateAutofocus(1)}
                                                        />
                                                    </div>
                                                    <div className='input-group' style={{ marginBottom: '10px' }}>
                                                        <div className='input-group-prepend'>
                                                            <p
                                                                className='input-group-text'
                                                                id='basic-addon'
                                                                style={{ fontWeight: 'bold' }}
                                                            >
                                                                First Name:
                                                            </p>
                                                        </div>
                                                        <input
                                                            className='form-control'
                                                            placeholder='First Name'
                                                            aria-label='FirstName'
                                                            aria-describedby='basic-addon'
                                                            type='firstName'
                                                            label='New firstName'
                                                            icon='user-cog'
                                                            group='true'
                                                            name='firstName'
                                                            value={this.state.firstName}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className='input-group' style={{ marginBottom: '10px' }}>
                                                        <div className='input-group-prepend'>
                                                            <span className='input-group-text' id='basic-addon'>
                                                                <i className='fas fa-envelope' />
                                                            </span>
                                                        </div>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Email'
                                                            aria-label='Email'
                                                            aria-describedby='basic-addon'
                                                            type='Emails'
                                                            label='Email'
                                                            icon='envelope'
                                                            group='true'
                                                            name='Email'
                                                            value={this.state.Email}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className='input-group' style={{ marginBottom: '10px' }}>
                                                        <div className='input-group-prepend'>
                                                            <span className='input-group-text' id='basic-addon'>
                                                                <i className='fas fa-map-marker' />
                                                            </span>
                                                        </div>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Address'
                                                            aria-label='Address'
                                                            aria-describedby='basic-addon'
                                                            type='Address'
                                                            label='New Address'
                                                            icon='map-marker-alt'
                                                            group='true'
                                                            name='Address'
                                                            value={this.state.Address}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className='input-group' style={{ marginBottom: '10px' }}>
                                                        <div className='input-group-prepend'>
                                                            <span className='input-group-text' id='basic-addon'>
                                                                <i className='fas fa-map-marker-alt' />
                                                            </span>
                                                        </div>
                                                        <input
                                                            className='form-control'
                                                            placeholder='City'
                                                            aria-label='City'
                                                            aria-describedby='basic-addon'
                                                            type='City'
                                                            label='City'
                                                            icon='map-marker-alt'
                                                            group='true'
                                                            name='City'
                                                            value={this.state.City}
                                                            onChange={this.handleChange}
                                                        />
                                                        <input
                                                            className='form-control'
                                                            placeholder='State'
                                                            aria-label='State'
                                                            aria-describedby='basic-addon'
                                                            type='State'
                                                            label='State'
                                                            icon='map-marker-alt'
                                                            group='true'
                                                            name='State'
                                                            value={this.state.State}
                                                            onChange={this.handleChange}
                                                        />
                                                        <input
                                                            className='form-control'
                                                            placeholder='ZipCode'
                                                            aria-label='ZipCode'
                                                            aria-describedby='basic-addon'
                                                            type='ZipCode'
                                                            label='ZipCode'
                                                            icon='map-marker-alt'
                                                            group='true'
                                                            name='ZipCode'
                                                            value={this.state.ZipCode}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className='input-group' style={{ marginBottom: '10px' }}>
                                                        <div className='input-group-prepend'>
                                                            <span className='input-group-text' id='basic-addon'>
                                                                <i className='fas fa-phone-alt' />
                                                            </span>
                                                        </div>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Phone Number'
                                                            aria-label='Phone'
                                                            aria-describedby='basic-addon'
                                                            type='Contact'
                                                            label='Contact'
                                                            icon='phone-alt'
                                                            group='true'
                                                            name='Contact'
                                                            value={this.state.Contact}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>

                                                    <MDBBtn
                                                        color='mdb-color'
                                                        rounded
                                                        className='float-right'
                                                        onClick={this.handleNextPrevClick(1)(2)}
                                                    >
                                                        next
                                                    </MDBBtn>
                                                </MDBCol>
                                            )}

                                            {this.state.formActivePanel1 === 2 && (
                                                <MDBCol md='12'>
                                                    <h3 className='font-weight-bold pl-0 my-4'>
                                                        <strong>Personal Data</strong>
                                                    </h3>
                                                    <SectionContainer header='Upload Resume:'>
                                                        <MDBFileInput
                                                            btnTitle='CHOOSE RESUME FILE'
                                                            getValue={this.fileInputHandler}
                                                        />
                                                    </SectionContainer>
                                                    <SectionContainer header='The following questions are entirely optional:'>
                                                        <div>
                                                            <ul style={{ fontWeight: 'bold', color: 'grey' }}>
                                                                <li>
                                                                    To comply with government Equal Employment
                                                                    Opportunity / Affirmative Action reporting
                                                                    regulations, we are requesting (but NOT requiring)
                                                                    that you enter this personal data. This information
                                                                    will not be used in connection with any employment
                                                                    decisions, and will be used solely as permitted by
                                                                    state and federal law. Your voluntary cooperation
                                                                    would be appreciated.
                                                                </li>
                                                                <li>
                                                                    <MDBLink to='/disclaimer'>Learn More</MDBLink>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <hr
                                                            className='my-5'
                                                            style={{ borderColor: 'purple', borderWidth: '2px' }}
                                                        />

                                                        <div>
                                                            <MDBRow className='d-flex justify-content-center'>
                                                                <MDBCol md='6'>
                                                                    <Gender onChange={this.handleGender} />
                                                                </MDBCol>
                                                                <MDBCol md='6'>
                                                                    <Race onChange={this.handleRace} />
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </div>
                                                    </SectionContainer>
                                                    <MDBBtn
                                                        color='mdb-color'
                                                        rounded
                                                        className='float-left'
                                                        onClick={this.handleNextPrevClick(1)(1)}
                                                    >
                                                        previous
                                                    </MDBBtn>
                                                    <MDBBtn
                                                        color='mdb-color'
                                                        rounded
                                                        className='float-right'
                                                        onClick={this.handleNextPrevClick(1)(3)}
                                                    >
                                                        next
                                                    </MDBBtn>
                                                </MDBCol>
                                            )}

                                            {this.state.formActivePanel1 === 3 && (
                                                <MDBCol md='12'>
                                                    <h3 className='font-weight-bold pl-0 my-4'>
                                                        <strong>Terms and conditions</strong>
                                                    </h3>
                                                    <MDBInput
                                                        type='checkbox'
                                                        label='I agreee to the terms and conditions'
                                                        group
                                                        name='Position'
                                                        value={this.state.Position}
                                                        onChange={this.handleChange}
                                                        id='checkbox'
                                                        autoFocus={this.calculateAutofocus(1)}
                                                    />
                                                    <MDBInput
                                                        label='I want to receive newsletter'
                                                        type='checkbox'
                                                        id='checkbox2'
                                                        name='Position'
                                                        value={this.state.Position}
                                                        onChange={this.handleChange}
                                                    />
                                                    <MDBBtn
                                                        color='mdb-color'
                                                        rounded
                                                        className='float-left'
                                                        onClick={this.handleNextPrevClick(1)(2)}
                                                    >
                                                        previous
                                                    </MDBBtn>
                                                    <MDBBtn
                                                        color='mdb-color'
                                                        rounded
                                                        className='float-right'
                                                        onClick={this.handleNextPrevClick(1)(4)}
                                                    >
                                                        next
                                                    </MDBBtn>
                                                </MDBCol>
                                            )}

                                            {this.state.formActivePanel1 === 4 && (
                                                <MDBCol md='12'>
                                                    <h3 className='font-weight-bold pl-0 my-4'>
                                                        <strong>Finish</strong>
                                                    </h3>
                                                    <h2 className='text-center font-weight-bold my-4'>
                                                        Registration completed!
                                                    </h2>
                                                    <MDBBtn
                                                        color='mdb-color'
                                                        rounded
                                                        className='float-left'
                                                        onClick={this.handleNextPrevClick(1)(3)}
                                                    >
                                                        previous
                                                    </MDBBtn>
                                                    <MDBBtn
                                                        color='success'
                                                        rounded
                                                        className='float-right'
                                                        onClick={this.handleSubmit}
                                                    >
                                                        submit
                                                    </MDBBtn>
                                                </MDBCol>
                                            )}
                                        </MDBRow>
                                    </form>
                                </MDBContainer>
                            </MDBCard>
                        </section>
                    </main>
                </MDBContainer>
                <Footer
                    style={{ ...dynamicLeftPadding, position: 'fixed', width: '100%' }}
                    className='d-none d-lg-block'
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // orders: state.order.orders,
        // loading: state.order.loading,
        // token: state.auth.token,
        // userId: state.auth.userId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // onFetchOrders: (token, userId) => dispatch(authActions.fetchOrders(token, userId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepperExample);
