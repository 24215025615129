import React from 'react';
import {
    MDBIcon,
    MDBContainer,
    MDBBtn,
    MDBFooter
    // MDBCol,
    //  MDBRow
} from 'mdbreact';

const Copyrights = (props) => {
    const date = new Date().getFullYear();
    return (
        // <MDBFooter color="blue" className="font-small pt-4 mt-4">
        <MDBFooter className={props.className} style={{ ...props.style, zIndex: 2, backgroundColor: 'black' }}>
            <MDBContainer className='text-center'>
                <MDBBtn size='lg' tag='a' floating social='fb' href='https://www.facebook.com/mhhcares' target='_blank'>
                    <MDBIcon fab icon='facebook-f' className='pr-1' /> Facebook
                </MDBBtn>
                <MDBBtn
                    size='lg'
                    tag='a'
                    floating
                    social='li'
                    href='https://www.linkedin.com/company/mhhcare'
                    target='_blank'
                >
                    <MDBIcon fab icon='linkedin-in' className='pr-1' /> Linkedin
                </MDBBtn>
                <MDBBtn
                    size='lg'
                    tag='a'
                    floating
                    social='ins'
                    href='https://www.instagram.com/mhhcare/'
                    target='_blank'
                >
                    <MDBIcon fab icon='instagram' className='pr-1' /> Instagram
                </MDBBtn>
                <MDBBtn
                    size='lg'
                    tag='a'
                    floating
                    social='yt'
                    href='https://www.youtube.com/channel/UCqWEhgvAC-UhyR5uk5yFc8A?view_as=subscriber'
                    target='_blank'
                >
                    <MDBIcon fab icon='youtube' />
                </MDBBtn>
            </MDBContainer>
            <p className='footer-copyright mb-0 py-3 text-center'>
                &copy; {date} Copyright: <a href='https://www.mhhcare.com/'>MHHC</a>
            </p>
        </MDBFooter>
    );
};
export default Copyrights;
