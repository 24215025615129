import React, { Component } from 'react';
import '../../App.css';
import AdminSideNavigation from './adminSidebar';
import AdminTopNavigation from './adminNav';
import Footer from './adminFooter';
import Routes from './adminRoutes';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authActions from '../../store/actions/authAction';

class AdminRoutesWithNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            windowWidth: 0,
            currentPage: '',
            sideNavToggled: false,
            sideNavLeft: false,
            breakWidth: 1400
        };
    }

    componentDidUpdate(prevProps, nextProps, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.assessLocation(this.props.location.pathname);
        }
    }
    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        this.assessLocation(this.props.location.pathname);
        // window.removeEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth
        });
    };

    toggleSideNav = () => {
        if (this.state.windowWidth < this.state.breakWidth) {
            this.setState({
                sideNavToggled: !this.state.sideNavToggled
            });
        }
    };

    sidenavToggle = (sidenavId) => () => {
        const sidenavNr = `sideNav${sidenavId}`;
        this.setState({
            [sidenavNr]: !this.state[sidenavNr]
        });
    };
    assessLocation = (location) => {
        let locationString;
        switch (location) {
            // Home:
            case '/adminHome':
                locationString = 'Admin Home';
                break;

            // UPLOADS:
            case '/upload-timeSheets':
                locationString = 'TIMESHEETS PDFS';
                break;
            //Orders
            case '/invoice-page':
                locationString = 'Invoice page';
                break;
            case '/customers-page':
                locationString = 'Customers page';
                break;
            case '/support-page':
                locationString = 'Support page';
                break;
            //JOBAPPLICATIONS
            case '/candidates-info':
                locationString = 'JOB CANDIDATES LIST';
                break;
            //UPLOADFILES
            case '/adminUpload':
                locationString = 'TIMESHEETS UPLOAD FOR PCAs';
                break;
            default:
        }
        this.setState({
            currentPage: locationString
        });
    };

    render() {
        const dynamicLeftPadding = {
            paddingLeft: this.state.windowWidth > this.state.breakWidth ? '240px' : '0'
        };
        if (!this.props.isAuthenticated) {
            return <Redirect to='/' />;
        } else {
            return (
                <div className='app'>
                    <div className='fixed-sn mdb-skin'>
                        <AdminSideNavigation
                            slim
                            fixed
                            breakWidth={this.state.breakWidth}
                            style={{ transition: 'all .3s', fontSize: '5px' }}
                            triggerOpening={this.state.sideNavLeft}
                            onLinkClick={() => this.toggleSideNav()}
                        />
                    </div>

                    <div className='flexible-content white-skin'>
                        <AdminTopNavigation
                            toggle={this.state.windowWidth < this.state.breakWidth}
                            onSideNavToggleClick={this.sidenavToggle('Left')}
                            routeName={this.state.currentPage}
                            className='white-skin'
                        />
                        <main style={{ ...dynamicLeftPadding, margin: '8rem 6% 6rem' }}>
                            <Routes {...this.props} onChange={() => this.assessLocation()} />
                        </main>

                        <Footer
                            style={{ ...dynamicLeftPadding, position: 'fixed', width: '100%' }}
                            className='d-none d-lg-block'
                        />
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onTryAutoSignup: () => dispatch(authActions.authCheckState())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminRoutesWithNavigation));
