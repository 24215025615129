import React from 'react';
import saveAs from 'file-saver';
import PDF from '../../assets/images/pdf.svg';
import moment from 'moment';
export const ADD_TIMESHEETS = 'ADD_TIMESHEETS';
export const SET_TIMESHEETS = 'SET_TIMESHEETS';
export const SET_DATATABLE = 'SET_DATATABLE';

export const addTimeSheets = () => {};
const downloadFile = async (fileName) => {
    // let url = `http://10.0.0.11:5000/files/files/${fileName}`;
    let url = process.env.REACT_APP_API_ENDPOINT + `/files/files/${fileName}`;
    // let url = `https://mhhc.herokuapp.com/files/files/${fileName}`;

    const res = await fetch(url);
    const blob = await res.blob();
    saveAs(blob, fileName);
};
export const loadFiles = () => {
    return async (dispatch) => {
        try {
            let config = {
                method: 'GET'
                // headers: {
                //     'Content-Type': 'multipart/form-data',
                //     // Accept: 'application/vnd.heroku+json; version=3'
                // }
            };
            // const response = await fetch('http://10.0.0.11:5000/files/files', config);
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/files/files', config);
            // const response = await fetch('https://mhhc.herokuapp.com/files/files', config);

            // if (!response.ok) {
            // 	throw new Error('Something went wrong!');
            // }
            const resData = await response.json();
            const loadedFiles = [];
            for (const key in resData) {
                let startDate = Date.parse(resData[key].metadata.UploadDate);
                let endDate = moment(new Date(startDate + 12096e5)).format('MM-DD-YYYY');
                let arrow = (
                    <button onClick={(fileName) => downloadFile(resData[key].filename)} className='download-btn'>
                        {'⇩'}
                    </button>
                );
                let image = <img alt='' src={PDF} style={{ width: '100px', height: '50px' }} />;
                loadedFiles.push({
                    // id: resData[key]._id,
                    // fileName: resData[key].filename,
                    files: image,
                    timesheet: resData[key].metadata.FileType.split(' ')[0],
                    // fileDescription: resData[key].metadata.UploadDescription,
                    payrollStartDate: resData[key].metadata.UploadDate,
                    payrollEndDate: endDate,
                    fileDownload: arrow
                });
            }
            let j = 0;
            const cols = [];
            loadedFiles.map((file, i) => {
                let keys = Object.keys(file);
                let len = keys.length;

                while (j < len) {
                    if (cols.includes(keys[j])) {
                        continue;
                    } else {
                        cols.push({
                            label: keys[j],
                            field: keys[j],
                            sort: 'asc',
                            width: 150
                        });
                    }

                    j++;
                }
                return cols;
            });

            dispatch({
                type: SET_TIMESHEETS,
                timeSheets: loadedFiles,
                tableCols: cols
            });
        } catch (error) {
            console.log('err', error);
        }
    };
};

export const loadDatable = () => {
    return async (dispatch) => {
        try {
            let loadedDatable = [];
            dispatch({
                type: SET_DATATABLE,
                TimeSheetTable: loadedDatable
            });
        } catch (err) {
            // throw err;
        }
    };
};

// let f = file[ObjVal];
// return (
// 	console.log('file', file),
// 	console.log('loadedFiles[i]', loadedFiles[i]),
// 	console.log('keys', keys.length)
// );
