import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBMask, MDBView, MDBCard } from 'mdbreact';
import './Staff.css';
import Nav from '../Nav';
import According from './According';
import Footer from '../Footer';
class Staff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseID: '',
            photoIndex: 0,
            isOpen: false,
            windowWidth: 0,
            breakWidth: 1400
        };
    }

    toggleCollapse = (collapseID) => () =>
        this.setState((prevState) => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ''
        }));

    render() {
        const dynamicLeftPadding = {
            paddingLeft: this.state.windowWidth > this.state.breakWidth ? '240px' : '0'
        };
        return (
            <React.Fragment>
                <div id='posts2'>
                    <Nav />
                    <main style={{ margin: '5rem 0% 10rem', marginTop: '50px' }}>
                        <section id='home2'>
                            <MDBView>
                                <MDBMask
                                    className='d-flex justify-content-center align-items-center'
                                    overlay='blue-light'
                                >
                                    <MDBContainer className='h-100 d-flex justify-content-center align-items-center'>
                                        <MDBCard className='rgba-black-strong white-text z-depth-1'>
                                            <div className='p-md-4 mb-0'>
                                                <MDBRow>
                                                    <MDBCol md='12' className='mt-5 mx-auto text-center'>
                                                        <div className='text-center'>
                                                            <h1 className='h1-reponsive white-text text-uppercase font-weight-bold mb-3'>
                                                                <strong>MEET THE TEAM</strong>
                                                            </h1>
                                                            <hr className='hr-light mt-4 wow fadeInDown' />
                                                            <h5 className='text-uppercase mb-5 white-text wow fadeInDown'>
                                                                <strong> SCROLL DOWN </strong>
                                                            </h5>
                                                            <span
                                                                style={{
                                                                    fontSize: '100px',
                                                                    color: 'white',
                                                                    fontWeight: 'bold'
                                                                }}
                                                            >
                                                                &#8659;
                                                            </span>
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>
                                            </div>
                                        </MDBCard>
                                    </MDBContainer>
                                </MDBMask>
                            </MDBView>
                        </section>

                        <MDBContainer>
                            <section
                                id='recent'
                                className=' my-5 text-center text-lg-left'
                                style={{ margin: '0rem 6% 5rem' }}
                            >
                                <According />
                            </section>
                        </MDBContainer>
                    </main>
                </div>
                {/* <Footer
                    style={{ ...dynamicLeftPadding, position: 'fixed', width: '100%' }}
                    className='d-none d-lg-block'
                /> */}
                <Footer style={{ ...dynamicLeftPadding, width: '100%', marginTop: 50 }} className='d-none d-lg-block' />
            </React.Fragment>
        );
    }
}

export default Staff;
