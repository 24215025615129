import React, { useEffect, Suspense } from 'react';
import './App.css';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Main from './components/pages/Home/Main';
import About from './components/pages/About/About';
import Admin from './components/adminPages/adminRoutesWithNavigation';
import Services from './components/pages/Service/Services';
import Insurance from './components/pages/Insurance/Inusrance';
import Staff from './components/pages/Teams/Staff';
import Careers from './components/pages/Careers/CareerPage';
import TimeSheetFiles from './components/pages/EmployeeResources/TimeSheetFiles';
import PcaCertification from './components/pages/EmployeeResources/PcaCertification';
import Contact from './components/pages/Contact/Contact';
import News from './components/pages/EmployeeResources/EmployeeNews';
import Disclaimer from './components/pages/Careers/Disclaimer';
import JobApplication from './components/pages/Careers/JobApplication';
import UploadModal from './components/pages/EmployeeResources/uploadModal';
import CommingSoon from './CommingSoon/Csf.js';
import * as authActions from './store/actions/authAction';
import PcaFinder from './components/pages/EmployeeResources/pcaFinder';
import ResetPassword from './components/pages/resetPassword';

const SignUp = React.lazy(() => {
    return import('./components/pages/SignUp');
});
const Login = React.lazy(() => {
    return import('./components/pages/Login');
});

const App = (props) => {
    const { onTryAutoSignup } = props;
    const fourtOFour = () => <h1 className='text-center'>404</h1>;

    useEffect(
        () => {
            onTryAutoSignup();
        },
        [ onTryAutoSignup ]
    );
    let routes = (
        <Switch>
            <Route path='/login' render={(props) => <Login {...props} />} />
            <Route path='/sign-up' render={(props) => <SignUp {...props} />} />
            <Route path='/' exact component={Main} />
            <Route path='/about' exact component={About} />
            <Route path='/services' exact component={Services} />
            <Route path='/insurance' exact component={Insurance} />
            <Route path='/staff' exact component={Staff} />
            <Route path='/timeSheetFiles' exact component={TimeSheetFiles} />
            <Route path='/pca-certification' exact component={PcaCertification} />
            <Route path='/employee-upload-timeSheets' exact component={UploadModal} />
            <Route path='/careers' exact component={Careers} />
            <Route path='/employee-news' exact component={News} />
            <Route path='/contact' exact component={Contact} />
            <Route path='/coming-soon' component={CommingSoon} />
            <Route path='/pca-finder' component={PcaFinder} />
            <Route path='/404' component={fourtOFour} />
            <Route path='/reset-password/:token' component={ResetPassword} />
            <Redirect to='/login' />
        </Switch>
    );
    if (props.isAuthenticated) {
        routes = (
            <Switch>
                <Route path='/login' render={(props) => <Login {...props} />} />
                <Route path='/' exact component={Main} />
                <Route path='/about' exact component={About} />
                <Route path='/services' exact component={Services} />
                <Route path='/insurance' exact component={Insurance} />
                <Route path='/staff' exact component={Staff} />
                <Route path='/timeSheetFiles' exact component={TimeSheetFiles} />
                <Route path='/pca-certification' exact component={PcaCertification} />
                <Route path='/employee-upload-timeSheets' exact component={UploadModal} />
                <Route path='/careers' exact component={Careers} />
                <Route path='/employee-news' exact component={News} />
                <Route path='/contact' exact component={Contact} />
                <Route path='/application' exact component={JobApplication} />
                <Route path='/disclaimer' exact component={Disclaimer} />
                <Route path='coming-soon' component={CommingSoon} />
                <Route path='/pca-finder' component={PcaFinder} />
                <Route path='/reset-password/:token' component={ResetPassword} />
                <Route path='/404' component={fourtOFour} />
                <Admin />

                <Redirect to='/' />
            </Switch>
        );
    }

    return (
        <div>
            <Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onTryAutoSignup: () => dispatch(authActions.authCheckState())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
