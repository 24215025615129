import React, { Component } from 'react';
import { MDBSelect, MDBContainer } from 'mdbreact';

class Race extends Component {
	state = {
		options: [
			{
				value: 'Hispanic or Latino',
				text: 'Hispanic or Latino'
			},
			{
				value: 'White, or not Hispanic or Latino',
				text: 'White, or not Hispanic or Latino'
			},
			{
				value: 'Black or African-American',
				text: 'Black or African-American'
			},
			{
				value: 'Asain or Asain American',
				text: 'Asain or Asain American'
			},
			{
				value: 'Native Hawaiin or other Pacific islander',
				text: 'Native Hawaiin or other Pacific islander'
			}
		]
	};
	onChange = (ev) => {
		console.log(ev);
	};

	render() {
		return (
			<MDBContainer>
				<h6 style={{ fontWeight: 'bold', color: 'grey' }}>RACE/ETHNICITY</h6>
				<MDBSelect
					options={this.state.options}
					getValue={this.props.onChange}
					label="Decline to answer"
					selectAll
				/>
			</MDBContainer>
		);
	}
}

export default Race;
