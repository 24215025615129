import React from 'react';
import { MDBJumbotron, MDBBtn, MDBContainer, MDBIcon, MDBCardBody, MDBCardTitle } from 'mdbreact';

const Explanation = () => {
	return (
		<MDBContainer className=" text-center">
			<MDBJumbotron
				style={{
					backgroundColor: 'rgba(20,82,148,1)',
					fontWeight: 'bold',
					borderRadius: 15,
					padding: '20px 20px 0px 20px',
					margin: 15
				}}
			>
				<MDBCardBody
					style={{
						marginBottom: 0
					}}
				>
					<MDBCardTitle className="h3 white-text font-weight-bold">TimeSheetFiles</MDBCardTitle>
					<h6 style={{ color: 'rgb(185,185,185,1)', textAlign: 'center', fontWeight: 'bold', fontSize: 18 }}>
						Your completed timesheets can be emailed to timesheets@mhhcare.com. If you pick your check up in
						person, please remember to bring your original timesheet with you on payday in exchange for your
						check. If you don’t have access to a computer or scanner, simply take a picture of your
						timesheet with a smart phone camera and then email to timesheets@mhhcare.com. If you are unable
						to personally pick up your paycheck please download the printable document here in order to
						authorize another person to do so on your behalf. For your convenience please make sure the
						directions included on the form are followed.
					</h6>
					<hr className="my-4" style={{ borderColor: 'white', borderWidth: '2px' }} />
					<div className="pt-2">
						<MDBBtn color="primary" href="/careers" className="waves-effect" size="md">
							Download App <MDBIcon far icon="gem" />
						</MDBBtn>
						<MDBBtn outline color="primary" className="waves-effect" size="md">
							{/* <a href="/employee-upload-timeSheets" download style={{ color: 'white' }}> */}
							<a href="/employee-upload-timeSheets" style={{ color: 'white' }}>
								UploadFile <MDBIcon icon="download" />
							</a>
						</MDBBtn>
					</div>
				</MDBCardBody>
			</MDBJumbotron>
			<hr className="my-5" style={{ backgroundColor: 'grey', marginBottom: 0, borderWidth: 5 }} />
		</MDBContainer>
	);
};

export default Explanation;
