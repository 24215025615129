import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon, MDBBtn, MDBInput, MDBContainer } from 'mdbreact';
import SectionContainer from '../../sectionContainer';
import axios from 'axios';

class MessagePage extends Component {
	state = {
		name: '',
		message: '',
		email: '',
		subject: '',
		sent: false,
		buttonText: 'Send Message'
	};
	formSubmit = (e) => {
		e.preventDefault();

		this.setState({
			buttonText: '...sending'
		});

		let data = {
			name: this.state.name,
			email: this.state.email,
			subject: this.state.subject,
			message: this.state.message
		};

		axios
			.post('/api/emailRoutes', data)
			.then((res) => {
				this.setState({ sent: true }, this.resetForm());
			})
			.catch(() => {
				console.log('Message not sent');
			});
	};
	resetForm = () => {
		this.setState({
			name: '',
			message: '',
			email: '',
			subject: '',
			buttonText: 'Message Sent'
		});
	};
	render() {
		return (
			<MDBContainer>
				<SectionContainer tag="section" noBorder className="contact-section">
					<MDBCard>
						<MDBRow>
							<MDBCol lg="8">
								<MDBCardBody className="form">
									<form onSubmit={(e) => this.formSubmit(e)}>
										<h3 className="mt-4">
											<MDBIcon icon="envelope" className="pr-2" />
											Write to us:
										</h3>

										<MDBRow>
											<MDBCol md="6">
												<div className="md-form mb-0">
													<MDBInput
														type="text"
														id="name"
														label="Your name"
														onChange={(e) => this.setState({ name: e.target.value })}
														value={this.state.name}
													/>
												</div>
											</MDBCol>
											<MDBCol md="6">
												<div className="md-form mb-0">
													<MDBInput
														type="text"
														id="email"
														label="Your email"
														onChange={(e) => this.setState({ email: e.target.value })}
														value={this.state.email}
													/>
												</div>
											</MDBCol>
										</MDBRow>

										<MDBRow>
											<MDBCol md="6">
												<div className="md-form mb-0">
													<MDBInput
														type="text"
														id="subject"
														label="Your Subject"
														onChange={(e) => this.setState({ subject: e.target.value })}
														value={this.state.subject}
													/>
												</div>
											</MDBCol>
										</MDBRow>
										<MDBRow>
											<MDBCol md="12">
												<div className="md-form mb-0">
													<MDBInput
														type="textarea"
														id="message"
														label="Your message"
														onChange={(e) => this.setState({ message: e.target.value })}
														value={this.state.message}
													/>
													<MDBBtn rounded color="blue" type="submit">
														{this.state.buttonText}

														<MDBIcon icon="paper-plane" />
													</MDBBtn>
												</div>
											</MDBCol>
										</MDBRow>
									</form>
								</MDBCardBody>
							</MDBCol>
							<MDBCol lg="4">
								<MDBCardBody className="contact text-center h-100 white-text">
									<h3 className="my-4 pb-2">Contact information</h3>
									<ul className="text-lg-left list-unstyled ml-4">
										<li>
											<p>
												<MDBIcon icon="map-marker-alt" className="pr-2" />
												2445 Park Ave, Minneapolis, MN 55404
											</p>
										</li>
										<li>
											<p>
												<MDBIcon icon="phone" className="pr-2" />(612) 343-3265
											</p>
										</li>
										<li>
											<p>
												<MDBIcon icon="envelope" className="pr-2" />
												generalinfo@mhhcare.com
											</p>
										</li>
									</ul>
									<hr className="hr-light my-4" />
									<ul className="list-inline text-center list-unstyled">
										<li className="list-inline-item">
											<a href="#!" className="p-2 fa-lg w-ic">
												<MDBIcon fab icon="twitter" />
											</a>
										</li>
										<li className="list-inline-item">
											<a href="#!" className="p-2 fa-lg w-ic">
												<MDBIcon fab icon="linkedin" />
											</a>
										</li>
										<li className="list-inline-item">
											<a href="#!" className="p-2 fa-lg w-ic">
												<MDBIcon fab icon="instagram" />
											</a>
										</li>
									</ul>
								</MDBCardBody>
							</MDBCol>
						</MDBRow>
					</MDBCard>
				</SectionContainer>
				<br />
			</MDBContainer>
		);
	}
}

export default MessagePage;
