import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment, { duration } from 'moment';
import useInterval from 'react-useinterval';

import '../styles/Countdown.css';

const Countdown = (props) => {
    const [ state, setTime ] = useState({
        days: 0,
        hours: 0,
        mins: 0,
        secs: 0
    });

    const addZeros = (value) => {
        value = String(value);
        while (value.length < 2) {
            value = `0${value}`;
        }
        return value;
    };
    const countdownDate = '2020-12-31 00:00:00';
    const setCountdown = () => {
        const futureDate = moment(countdownDate);

        const today = moment();

        const clockDuration = duration(futureDate.diff(today));

        const days = Math.floor(clockDuration.asDays());
        const hours = clockDuration.hours();
        const mins = clockDuration.minutes();
        const secs = clockDuration.seconds();

        setTime({
            days: days,
            hours: hours,
            mins: mins,
            secs: secs
        });
    };

    useInterval(() => {
        setCountdown();
    }, 1000);

    return (
        <div className='countdown'>
            {Object.keys(state).map((key, i) => (
                <div key={i} className='countdown-segment'>
                    <span className='countdown-segment-number'>{addZeros(state[key])}</span>
                    <span className='countdown-segment-caption'>{key.toUpperCase()}</span>
                </div>
            ))}
        </div>
    );
};

Countdown.propTypes = {
    countdownDate: PropTypes.string.isRequired
};

export default Countdown;
