import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

//Home
import Home from './home/adminHome';
import Main from '../pages/Home/Main';

//TIMESHEET UPLOADS
// import UploadedFiles from './Uploads/Upload';
import UploadedFiles from './Uploads/UploadCard';
import PreviewPage from './Uploads/PreviewPage';
//CUSTOMERS
import Invoice from '../adminPages/Orders/Invoice';
import Customers from '../adminPages/Orders/Cutomers';
import Support from '../adminPages/Orders/Support';

import FileViewer from './FileViewer/FileViewer';
import * as authActions from '../../store/actions/authAction';

//Auth
const Login = React.lazy(() => {
    return import('../pages/Login');
});

const AdminRoutes = (props) => {
    const fourtOFour = () => <h1 className='text-center'> 404 </h1>;
    let routes = (
        <Switch>
            <Route path='/login' render={(props) => <Login {...props} />} />
            <Route path='/' exact component={Main} {...props} /> <Route path='/adminHome' component={Home} />{' '}
            <Route path='/invoice-page' component={Invoice} />
            <Route path='/customers-page' component={Customers} />
            <Route path='/support-page' component={Support} />
            <Route path='/adminUpload' component={UploadedFiles} />
            <Route path='/timesheets-preview' component={PreviewPage} />
            <Route path='/adminUploadallfiles' component={FileViewer} /> <Redirect to='/' />
            <Route component={fourtOFour} />
        </Switch>
    );
    return <div> {routes} </div>;
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onTryAutoSignup: () => dispatch(authActions.authCheckState())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminRoutes));
