import React, { Component } from 'react';
import { MDBJumbotron, MDBBtn, MDBContainer, MDBRow, MDBCol, MDBFooter, MDBIcon } from 'mdbreact';
import Nav from '../Nav';
import Footer from '../Footer';
class Disclaimer extends Component {
	state = {
		windowWidth: 0,
		breakWidth: 1400
	};
	render() {
		const dynamicLeftPadding = {
			paddingLeft: this.state.windowWidth > this.state.breakWidth ? '240px' : '0'
		};
		return (
			<div>
				<Nav />
				<main style={{ ...dynamicLeftPadding, margin: '0rem 0% 0rem' }}>
					<MDBContainer className="mt-5 text-center">
						<MDBRow>
							<MDBCol>
								<MDBJumbotron>
									<h2 className="h1 display-3" style={{ color: 'black' }}>
										Disclaimer!
									</h2>
									<p className="lead" style={{ fontWeight: 'bold', color: 'red' }}>
										Anti-Discrimination Notice: It is an unlawful employment practice for an
										employer to fail or refuse to hire or discharge any individual, or otherwise to
										discriminate against any individual with respect to that individual’s terms and
										conditions of employment, because of such individual’s race, color, religion,
										sex, or national origin.
									</p>
									<hr className="my-2" />
									<ul style={{ textAlign: 'left', color: 'black', fontWeight: 'bold' }}>
										<li>
											This employer is subject to certain nondiscrimination and affirmative action
											recordkeeping and reporting requirements which require the employer to
											invite employees to voluntarily self-identify their race/ethnicity.
											Submission of this information is voluntary and refusal
										</li>
										<li>
											The information obtained will be kept confidential and may only be used in
											accordance with the provisions of applicable federal laws, executive orders,
											and regulations, including those which require the information to be
											summarized and reported to the Federal Government for civil rights
											enforcement purposes.
										</li>
										<li>
											If you choose not to self-identify your race/ethnicity at this time, the
											federal government requires this employer to determine this information by
											visual survey and/or other available information. For civil rights
											monitoring and enforcement purposes only, all race/ethnicity information
											will be collected and reported in the seven categories identified below.
										</li>
										<li>
											The definitions for each category have been established by the federal
											government. If you choose to voluntarily self-identify, you may mark only
											one of the options presented.
										</li>
									</ul>
									<p className="lead">
										<MDBBtn color="primary" href="/application">
											Return To Application
										</MDBBtn>
									</p>
								</MDBJumbotron>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</main>
				<MDBFooter className="pt-3 mt-5 text-center text-md-left">
					<MDBContainer>
						<MDBRow>
							<MDBCol md="3">
								<h6 className="text-uppercase font-weight-bold">Footer Content</h6>
								<p>Here you can use rows and columns here to organize your footer content.</p>
							</MDBCol>
							<hr className="w-100 clearfix d-md-none" />
							<MDBCol md="2" className="ml-auto">
								<h6 className="text-uppercase font-weight-bold">Links</h6>
								<ul className="list-unstyled">
									<li>
										<a href="#!">Link 1</a>
									</li>
									<li>
										<a href="#!">Link 2</a>
									</li>
									<li>
										<a href="#!">Link 3</a>
									</li>
									<li>
										<a href="#!">Link 4</a>
									</li>
								</ul>
							</MDBCol>
							<hr className="w-100 clearfix d-md-none" />
							<MDBCol md="2" className="ml-auto">
								<h6 className="text-uppercase font-weight-bold">Links</h6>
								<ul className="list-unstyled">
									<li>
										<a href="#!">Link 1</a>
									</li>
									<li>
										<a href="#!">Link 2</a>
									</li>
									<li>
										<a href="#!">Link 3</a>
									</li>
									<li>
										<a href="#!">Link 4</a>
									</li>
								</ul>
							</MDBCol>
							<hr className="w-100 clearfix d-md-none" />
							<MDBCol md="2" className="ml-auto">
								<h6 className="text-uppercase font-weight-bold">Links</h6>
								<ul className="list-unstyled">
									<li>
										<a href="#!">Link 1</a>
									</li>
									<li>
										<a href="#!">Link 2</a>
									</li>
									<li>
										<a href="#!">Link 3</a>
									</li>
									<li>
										<a href="#!">Link 4</a>
									</li>
								</ul>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
					<hr />
					<MDBContainer>
						<MDBRow>
							<MDBCol md="12">
								<ul className="list-unstyled d-flex justify-content-center mb-0 pb-0 pt-2 list-inline">
									<li className="list-inline-item">
										<MDBIcon fab icon="facebook" size="2x" className="white-text p-2 m-2" />
									</li>
									<li className="list-inline-item">
										<MDBIcon fab icon="twitter" size="2x" className="white-text p-2 m-2" />
									</li>
									<li className="list-inline-item">
										<MDBIcon fab icon="google-plus" size="2x" className="white-text p-2 m-2" />
									</li>
									<li className="list-inline-item">
										<MDBIcon fab icon="linkedin" size="2x" className="white-text p-2 m-2" />
									</li>
									<li className="list-inline-item">
										<MDBIcon fab icon="instagram" size="2x" className="white-text p-2 m-2" />
									</li>
									<li className="list-inline-item">
										<MDBIcon fab icon="pinterest" size="2x" className="white-text p-2 m-2" />
									</li>
								</ul>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
					<Footer />
				</MDBFooter>
			</div>
		);
	}
}

export default Disclaimer;
