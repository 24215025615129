import axios from 'axios';
// import ENV from '../../env';
//TODO: ADD LOCATION
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTHENTICATE = 'AUTHENTICATE';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const authStart = () => {
	return {
		type: AUTH_START
	};
};

export const authSuccess = (token, userId, role, username) => {
	return {
		type: AUTH_SUCCESS,
		token: token,
		userId: userId,
		role: role,
		username: username
	};
};

export const authFail = (error) => {
	return {
		type: AUTH_FAIL,
		error: error
	};
};

export const logout = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('expirationDate');
	localStorage.removeItem('userId');
	localStorage.removeItem('role');
	localStorage.removeItem('username');

	return { type: AUTH_LOGOUT };
};

export const checkAuthTimeout = (expirationTime) => {
	return (dispatch) => {
		setTimeout(() => {
			dispatch(logout());
		}, expirationTime);
	};
};
export const signup = (firstName, lastName, username, email, password) => {
	return async (dispatch) => {
		dispatch(authStart());
		const authData = {
			firstName: firstName,
			lastName: lastName,
			username: username,
			email: email,
			password: password
		};

		let url = process.env.REACT_APP_API_ENDPOINT + '/users/register-user';

		axios
			.post(url, authData)
			.then((response) => {
				console.log('response.data', response.status);
				alert(response.data.message);
				dispatch(setAuthRedirectPath('/login'));
				// const expirationTime = response.data.expiresIn;
				// const expirationDate = new Date(new Date().getTime() + parseInt(expirationTime) * 60 * 60 * 1000);
				// const username = response.data.username.substring(0, response.data.username.indexOf('@'));
				// localStorage.setItem('token', response.data.token);
				// localStorage.setItem('expirationDate', expirationDate);
				// localStorage.setItem('userId', response.data.user_id);
				// localStorage.setItem('role', response.data.role);
				// localStorage.setItem('username', username);
				// dispatch(authSuccess(response.data.user_id, response.data.token, response.data.role, username));
			})
			.catch((error) => {
				const err = error.response.data.message ? error.response.data.message : 'error';

				dispatch(authFail(err));
				alert(err);
				console.log('err', err);
				// console.log('error json parse', JSON.parse(error));

				// alert(dispatch(authFail(error.response.data.message)));
				// dispatch(console.log(error.response.data.message));
			});
	};
};
export const login = (email, password, role, nonAdmin) => {
	return (dispatch) => {
		dispatch(authStart());
		let config = {
			// headers: {
			//     Accept: 'application/vnd.heroku+json; version=3'
			// }
		};
		const authData = {
			email: email,
			password: password,
			role: role
		};

		// let url = 'http://10.0.0.11:5000/api/users/login-user';
		let url = process.env.REACT_APP_API_ENDPOINT + '/users/login-user';
		// let url = 'https://mhhc.herokuapp.com/api/users/login-user';

		if (!nonAdmin) {
			// url = 'http://10.0.0.11:5000/api/users/login-super-admin';
			url = process.env.REACT_APP_API_ENDPOINT + '/users/login-super-admin';
			// url = 'https://mhhc.herokuapp.com/api/users/login-super-admin';
		}
		axios
			// .post(url, authData)
			.post(url, authData, config)
			.then((response) => {
				console.log('response.data', response.data);
				const expirationTime = response.data.expiresIn;
				const expirationDate = new Date(new Date().getTime() + parseInt(expirationTime) * 60 * 60 * 1000);
				const username = response.data.username;
				localStorage.setItem('token', response.data.token);
				localStorage.setItem('expirationDate', expirationDate);
				localStorage.setItem('userId', response.data.user_id);
				localStorage.setItem('role', response.data.role);
				localStorage.setItem('username', username);
				dispatch(authSuccess(response.data.user_id, response.data.token, response.data.role, username));
				// dispatch(checkAuthTimeout(response.data.expiresIn));
			})
			.catch((error) => {
				console.log('error', error);

				const err = error.response.data.message ? error.response.data.message : 'error';
				dispatch(authFail(err));
				alert(err);
				console.log('err', err);
			});
	};
};
export const resPass = (email) => {
	return (dispatch) => {
		// const email = email
		let url = process.env.REACT_APP_API_ENDPOINT + '/users/recover';
		axios
			.post(url, { email })
			.then((response) => {
				console.log('response4recover', response);
			})
			.catch((error) => {
				console.log('error4recover', error);
				alert(error);
			});
	};
};
export const createNewPass = (password, token) => {
	return (dispatch) => {
		console.log(password, token);
		let url = process.env.REACT_APP_API_ENDPOINT + '/users/reset/' + token;
		axios
			.post(url, { password })
			.then((response) => {
				console.log(response);
				// response.headers.get('Location');
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const setAuthRedirectPath = (path) => {
	return {
		type: SET_AUTH_REDIRECT_PATH,
		path: path
	};
};

export const authCheckState = () => {
	return (dispatch) => {
		const token = localStorage.getItem('token');
		if (!token) {
			dispatch(logout());
		} else {
			const expirationDate = new Date(localStorage.getItem('expirationDate'));
			if (expirationDate <= new Date()) {
				dispatch(logout());
			} else {
				const userId = localStorage.getItem('userId');
				const role = localStorage.getItem('role');
				const username = localStorage.getItem('username');
				dispatch(authSuccess(token, userId, role, username));
				dispatch(checkAuthTimeout(expirationDate.getTime() - new Date().getTime()));
			}
		}
	};
};
