import React, { Component } from 'react';
import {
	MDBContainer,
	MDBCollapse,
	MDBCard,
	MDBCardBody,
	MDBCollapseHeader,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBAvatar,
	MDBCardHeader
} from 'mdbreact';
import Dad from '../../../assets/images/dad.jpg';
import Bashir from '../../../assets/images/uncleBashir.jpg';
import rwagner from '../../../assets/images/rWagner.jpg';
import Place_holder from '../../../assets/images/Portrait_Placeholder.png';
class CollapsePage extends Component {
	state = {
		collapseID: 'collapse1'
	};

	toggleCollapse = (collapseID) => () =>
		this.setState((prevState) => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : ''
		}));

	render() {
		return (
			<div>
				<MDBContainer
					className="accordion md-accordion accordion-3 z-depth-1-half"
					style={{ backgroundColor: 'rgba(20,82,148,1)', border: '5px solid grey' }}
				>
					<MDBCardHeader className="form-header blue-gradient rounded">
						<div className="d-flex justify-content-center ">
							<MDBIcon icon="clinic-medical" className="White-text mx-3" size="2x" />
							<MDBIcon icon="user-nurse" className="White-text mx-3" size="2x" />
							<MDBIcon icon="laptop-medical" className="White-text mx-3" size="2x" />
						</div>
						<h2 className="text-center text-uppercase white-text py-4 px-3" style={{ fontWeight: 'bold' }}>
							THE TEAM
						</h2>
					</MDBCardHeader>

					<MDBCard>
						<MDBCollapseHeader
							onClick={this.toggleCollapse('collapse1')}
							tag="h4"
							tagClassName="blue-text d-flex justify-content-between align-items-center"
						>
							<strong>INTAKE TEAM</strong>
							<MDBIcon
								icon={this.state.collapseID === 'collapse1' ? 'angle-up' : 'angle-down'}
								className="blue-text"
								size="2x"
							/>
						</MDBCollapseHeader>
						<MDBCollapse id="collapse1" isOpen={this.state.collapseID}>
							<MDBCardBody className="pt-0">
								<MDBRow className="text-md-left">
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Hinda Mohamed</h5>
											<h6 className="mb-3">Intake &amp; Service Coordinator</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:hmohamed@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>hmohamed@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>

									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Cynthia Moreno</h5>
											<h6 className="mb-3">Intake &amp; Service Coordinator</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:cmoreno@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>cmoreno@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
								</MDBRow>
							</MDBCardBody>
						</MDBCollapse>
					</MDBCard>
					<hr
						style={{
							borderColor: 'rgba(20,82,148,1)',
							borderWidth: '2px',
							marginTop: '0px',
							marginBottom: '10px'
						}}
					/>
					<MDBCard>
						<MDBCollapseHeader
							onClick={this.toggleCollapse('collapse2')}
							tag="h4"
							tagClassName="blue-text d-flex justify-content-between align-items-center"
						>
							<strong>CASE MANAGER &amp; PCA SUPERVISOR</strong>
							<MDBIcon
								icon={this.state.collapseID === 'collapse2' ? 'angle-up' : 'angle-down'}
								className="blue-text"
								size="2x"
							/>
						</MDBCollapseHeader>
						<MDBCollapse id="collapse2" isOpen={this.state.collapseID}>
							<MDBCardBody className="pt-0">
								<MDBRow className="text-md-left">
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Adrianna Jones</h5>
											<h6 className="mb-3">Lead Case Manager &amp; PCA Supervisor</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:ajones@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>ajones@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Angela Akenson</h5>
											<h6 className="mb-3">Case Manager &amp; PCA Supervisor</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:aakenson@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>aakenson@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3"> Brittany Fisher</h5>
											<h6 className="mb-3">Case Manager &amp; PCA Supervisor</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:bfisher@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>bfisher@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Tara Stevens</h5>
											<h6 className="mb-3">Case Manager &amp; PCA Supervisor</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:tstevens@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>tstevens@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Jennifer Thompson</h5>
											<h6 className="mb-3">
												Case Manager &amp; PCA Supervisor (St. Cloud office)
											</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:jthompson@mhhcare.com‎"
											>
												<MDBIcon icon="envelope" /> <strong>jthompson@mhhcare.com‎</strong>
											</a>
										</MDBCol>
									</MDBCol>
								</MDBRow>
							</MDBCardBody>
						</MDBCollapse>
					</MDBCard>
					<hr
						style={{
							borderColor: 'rgba(20,82,148,1)',
							borderWidth: '2px',
							marginTop: '0px',
							marginBottom: '10px'
						}}
					/>
					<MDBCard>
						<MDBCollapseHeader
							onClick={this.toggleCollapse('collapse3')}
							tag="h4"
							tagClassName="blue-text d-flex justify-content-between align-items-center"
						>
							<strong>HUMAN RESOURCES COORDINATOR</strong>
							<MDBIcon
								icon={this.state.collapseID === 'collapse3' ? 'angle-up' : 'angle-down'}
								className="blue-text"
								size="2x"
							/>
						</MDBCollapseHeader>
						<MDBCollapse id="collapse3" isOpen={this.state.collapseID}>
							<MDBCardBody className="pt-0">
								<MDBRow className="justify-content-center align-items-center">
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Azizi Dunigan</h5>
											<h6 className="mb-3">Human Resources Coordinator</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:adunigan@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>adunigan@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
								</MDBRow>
							</MDBCardBody>
						</MDBCollapse>
					</MDBCard>
					<hr
						style={{
							borderColor: 'rgba(20,82,148,1)',
							borderWidth: '2px',
							marginTop: '0px',
							marginBottom: '10px'
						}}
					/>
					<MDBCard>
						<MDBCollapseHeader
							onClick={this.toggleCollapse('collapse4')}
							tag="h4"
							tagClassName="blue-text d-flex justify-content-between align-items-center"
						>
							<strong>PAYROLL AND TIMESHEET COORDINATOR</strong>
							<MDBIcon
								icon={this.state.collapseID === 'collapse4' ? 'angle-up' : 'angle-down'}
								className="blue-text"
								size="2x"
							/>
						</MDBCollapseHeader>
						<MDBCollapse id="collapse4" isOpen={this.state.collapseID}>
							<MDBCardBody className="pt-0">
								<MDBRow className="justify-content-center align-items-center">
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Guled Mohamud</h5>
											<h6 className="mb-3">Payroll Coordinator</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:gmohamed@mhhcare.com‎"
											>
												<MDBIcon icon="envelope" /> <strong>gmohamed@mhhcare.com‎</strong>
											</a>
										</MDBCol>
									</MDBCol>
								</MDBRow>
							</MDBCardBody>
						</MDBCollapse>
					</MDBCard>
					<hr
						style={{
							borderColor: 'rgba(20,82,148,1)',
							borderWidth: '2px',
							marginTop: '0px',
							marginBottom: '10px'
						}}
					/>
					<MDBCard>
						<MDBCollapseHeader
							onClick={this.toggleCollapse('collapse5')}
							tag="h4"
							tagClassName="blue-text d-flex justify-content-between align-items-center"
						>
							<strong>BILLING &amp; TIMESHEET COORDINATOR</strong>
							<MDBIcon
								icon={this.state.collapseID === 'collapse5' ? 'angle-up' : 'angle-down'}
								className="blue-text"
								size="2x"
							/>
						</MDBCollapseHeader>
						<MDBCollapse id="collapse5" isOpen={this.state.collapseID}>
							<MDBCardBody className="pt-0">
								<MDBRow className="text-md-left">
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Tiffany Hopkins</h5>
											<h6 className="mb-3">Billing &amp; Time Sheet Coordinator</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:thopkins@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>thopkins@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Ahmed Aden</h5>
											<h6 className="mb-3">BILLING &amp; TIMESHEET COORDINATOR</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:aakenson@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>aakenson@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
								</MDBRow>
							</MDBCardBody>
						</MDBCollapse>
					</MDBCard>
					<hr
						style={{
							borderColor: 'rgba(20,82,148,1)',
							borderWidth: '2px',
							marginTop: '0px',
							marginBottom: '10px'
						}}
					/>
					<MDBCard>
						<MDBCollapseHeader
							onClick={this.toggleCollapse('collapse6')}
							tag="h4"
							tagClassName="blue-text d-flex justify-content-between align-items-center"
						>
							<strong>TIMESHEET ENTRY/AUDIT &amp; RECORD COORDINATOR</strong>
							<MDBIcon
								icon={this.state.collapseID === 'collapse6' ? 'angle-up' : 'angle-down'}
								className="blue-text"
								size="2x"
							/>
						</MDBCollapseHeader>
						<MDBCollapse id="collapse6" isOpen={this.state.collapseID}>
							<MDBCardBody className="pt-0">
								<MDBRow className="text-md-left">
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Ayub Bihi</h5>
											<h6 className="mb-3">Time Sheet Entry/Audit &amp; Records Coordinator</h6>
											<a target="_blank" rel="noopener noreferrer" href="mailto:sali@mhhcare.com">
												<MDBIcon icon="envelope" /> <strong>sali@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Sakaria Ali</h5>
											<h6 className="mb-3">Time Sheet Entry/Audit &amp; Records Coordinator</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:sabdi@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>sabdi@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
								</MDBRow>
							</MDBCardBody>
						</MDBCollapse>
					</MDBCard>
					<hr
						style={{
							borderColor: 'rgba(20,82,148,1)',
							borderWidth: '2px',
							marginTop: '0px',
							marginBottom: '10px'
						}}
					/>
					<MDBCard>
						<MDBCollapseHeader
							onClick={this.toggleCollapse('collapse7')}
							tag="h4"
							tagClassName="blue-text d-flex justify-content-between align-items-center"
						>
							<strong>OFFICE ASSISTANT</strong>
							<MDBIcon
								icon={this.state.collapseID === 'collapse7' ? 'angle-up' : 'angle-down'}
								className="blue-text"
								size="2x"
							/>
						</MDBCollapseHeader>
						<MDBCollapse id="collapse7" isOpen={this.state.collapseID}>
							<MDBCardBody className="pt-0">
								<MDBRow className="text-md-left">
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Mohamedamin Abdi</h5>
											<h6 className="mb-3">Office Assistant</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:mabdi@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>mabdi@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Amal Farah</h5>
											<h6 className="mb-3">Part-time Office Assistant</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:afarah@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>afarah@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
								</MDBRow>
								<MDBRow className="text-md-left">
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Mohamedamin Abdi</h5>
											<h6 className="mb-3">Office Assistant</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:mabdi@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>mabdi@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Amal Farah</h5>
											<h6 className="mb-3">Part-time Office Assistant</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:afarah@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>afarah@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
								</MDBRow>
							</MDBCardBody>
						</MDBCollapse>
					</MDBCard>
					<hr
						style={{
							borderColor: 'rgba(20,82,148,1)',
							borderWidth: '2px',
							marginTop: '0px',
							marginBottom: '10px'
						}}
					/>
					<MDBCard>
						<MDBCollapseHeader
							onClick={this.toggleCollapse('collapse8')}
							tag="h4"
							tagClassName="blue-text d-flex justify-content-between align-items-center"
						>
							<strong>RECEPTION &amp; TIMESHEET AUDIT</strong>
							<MDBIcon
								icon={this.state.collapseID === 'collapse8' ? 'angle-up' : 'angle-down'}
								className="blue-text"
								size="2x"
							/>
						</MDBCollapseHeader>
						<MDBCollapse id="collapse8" isOpen={this.state.collapseID}>
							<MDBCardBody className="pt-0">
								<MDBRow className="justify-content-center align-items-center">
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Place_holder}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Sara Eshete</h5>
											<h6 className="mb-3">Receptionist and Time Sheet Audit</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:seshete@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>seshete@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
								</MDBRow>
							</MDBCardBody>
						</MDBCollapse>
					</MDBCard>
					<hr
						style={{
							borderColor: 'rgba(20,82,148,1)',
							borderWidth: '2px',
							marginTop: '0px',
							marginBottom: '10px'
						}}
					/>
					<MDBCard>
						<MDBCollapseHeader
							onClick={this.toggleCollapse('collapse9')}
							tag="h4"
							tagClassName="blue-text d-flex justify-content-between align-items-center"
						>
							<strong>EXECUTIVES</strong>
							<MDBIcon
								icon={this.state.collapseID === 'collapse9' ? 'angle-up' : 'angle-down'}
								className="blue-text"
								size="2x"
							/>
						</MDBCollapseHeader>
						<MDBCollapse id="collapse9" isOpen={this.state.collapseID}>
							<MDBCardBody className="pt-0">
								<MDBRow className="text-md-left">
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Dad}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Mohamud Hassan</h5>
											<h6 className="mb-3">CEO and Chairman</h6>
										</MDBCol>
									</MDBCol>
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={Bashir}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<h5 className="font-weight-bold black-text mb-3">Bashir Mohamed</h5>
											<h6 className="mb-3">Vice-President and CFO</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:bmohamed@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>bmohamed@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
								</MDBRow>
								<MDBRow className="text-md-left">
									<MDBCol lg="6" md="12" className="mb-5">
										<MDBCol md="4" lg="6" className="float-left">
											<MDBAvatar
												tag="img"
												src={rwagner}
												className="rounded-circle z-depth-1 img-fluid"
												alt="Sample avatar"
											/>
										</MDBCol>
										<MDBCol md="8" lg="6" className="float-right" style={{ textAlign: 'center' }}>
											<br />
											<h5 className="font-weight-bold black-text mb-3">Rose Wagner</h5>
											<h6 className="mb-3">Director of Operations</h6>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="mailto:rwagner@mhhcare.com"
											>
												<MDBIcon icon="envelope" /> <strong>rwagner@mhhcare.com</strong>
											</a>
										</MDBCol>
									</MDBCol>
								</MDBRow>
							</MDBCardBody>
						</MDBCollapse>
					</MDBCard>
					<hr
						style={{
							borderColor: 'rgba(20,82,148,1)',
							borderWidth: '2px',
							marginTop: '0px',
							marginBottom: '10px'
						}}
					/>
				</MDBContainer>
			</div>
		);
	}
}

export default CollapsePage;
