import React from 'react';
import { MDBRow, MDBCol, MDBIcon, MDBBtn, MDBContainer } from 'mdbreact';

const FeaturesPage = () => {
	return (
		<MDBContainer style={{ backgroundColor: 'rgba(20,82,148,1)', borderRadius: 10 }}>
			<h1
				className="h1-responsive font-weight-bold text-center my-5"
				style={{ fontSize: 35, paddingTop: 20, color: 'white' }}
			>
				OUR CORE VALUES:
			</h1>
			<p className="lead  w-responsive text-center mx-auto mb-5" style={{ fontWeight: 'bold', color: '#B9B9B9' }}>
				The care we give is based on respect for the dignity and worth of each and every client, we value
				professionalism, competency and team work amongst our staff.
			</p>
			<MDBRow>
				<MDBCol md="4" className="md-0 mb-5">
					<MDBRow>
						<MDBCol lg="2" md="3" size="2">
							<MDBIcon icon="universal-access" size="2x" className="orange-text" />
						</MDBCol>
						<MDBCol lg="10" md="9" size="10">
							<h4 className="font-weight-bold white-text">Independence</h4>
							<p className="text" style={{ fontWeight: 'bold', color: '#B9B9B9' }}>
								Our services are tailored to assist clients and their families to achieve an optimal
								level of function and independence.
							</p>
							<MDBBtn color="orange" size="sm">
								Learn more
							</MDBBtn>
						</MDBCol>
					</MDBRow>
				</MDBCol>
				<MDBCol md="4" className="md-0 mb-5">
					<MDBRow>
						<MDBCol lg="2" md="3" size="2">
							<MDBIcon icon="balance-scale" size="2x" className="blue-text" />
						</MDBCol>
						<MDBCol lg="10" md="9" size="10">
							<h4 className="font-weight-bold white-text">Ethics</h4>
							<p className="text" style={{ fontWeight: 'bold', color: '#B9B9B9' }}>
								Honesty, integrity, and fairness are the way we operate our business.
							</p>
							<MDBBtn color="blue" size="sm">
								Learn more
							</MDBBtn>
						</MDBCol>
					</MDBRow>
				</MDBCol>
				<MDBCol md="4" className="md-0 mb-5">
					<MDBRow>
						<MDBCol lg="2" md="3" size="2">
							<MDBIcon icon="users" size="2x" className="purple-text" />
						</MDBCol>
						<MDBCol lg="10" md="9" size="10">
							<h4 className="font-weight-bold white-text">Family</h4>
							<p className="text" style={{ fontWeight: 'bold', color: '#B9B9B9' }}>
								Clients and their families are empowered in the care planning process.
							</p>
							<MDBBtn color="purple" size="sm">
								Learn more
							</MDBBtn>
						</MDBCol>
					</MDBRow>
				</MDBCol>
			</MDBRow>
		</MDBContainer>
	);
};

export default FeaturesPage;
