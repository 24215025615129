import React from 'react';
import {
	MDBCarousel,
	MDBCard,
	MDBCarouselInner,
	MDBCarouselItem,
	MDBView,
	MDBMask,
	MDBIcon,
	MDBBtn,
	MDBAnimation
} from 'mdbreact';

import Building from '../../../assets/images/rightSide.jpg';
import AdultCare from '../../../assets/images/adultCare.jpg';
// import StateCapital from '../../../assets/images/stateCapital.jpg';
import AffordableCare from '../../../assets/images/AffordableCare.jpg';

const CarouselPage = (props) => {
	return (
		<MDBCarousel
			activeItem={1}
			length={3}
			showControls={true}
			showIndicators={true}
			className="z-depth-1"
			testimonial
			interval={4000}
		>
			<MDBCarouselInner>
				<MDBCarouselItem itemId="1">
					<MDBView>
						<img className="d-block w-100" src={Building} alt="First slide" />
						<MDBMask overlay="blue-grey-light" className="flex-center">
							<MDBAnimation reveal type="bounceInUp">
								<MDBCard
									style={{
										backgroundColor: 'rgba(20,82,148,0.6)',
										borderRadius: 10
									}}
								>
									<strong>
										<h2
											className="h2-responsive"
											style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}
										>
											<MDBIcon far icon="handshake" />
											DRIVEN BY EXCELLENCE
										</h2>
										<h2
											className="h2-responsive"
											style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}
										>
											A RESULTED BASED SERVICE
										</h2>
									</strong>
									<MDBBtn href="/services" gradient="aqua" size="lg">
										<MDBIcon icon="clone left" size="lg" />
										<strong stlye={{ fontWeight: 'bold' }}>LEARN MORE</strong>
									</MDBBtn>
								</MDBCard>
							</MDBAnimation>
						</MDBMask>
					</MDBView>
				</MDBCarouselItem>
				<MDBCarouselItem itemId="2">
					<MDBView>
						<img className="d-block w-100" src={AffordableCare} alt="Second slide" />
						<MDBMask overlay="blue-grey-light" className="flex-center">
							<MDBAnimation duration="3s" infinite>
								<MDBCard
									style={{
										backgroundColor: 'rgba(20,82,148,0.6)',
										borderRadius: 10
									}}
								>
									<strong>
										<h2
											className="h2-responsive"
											style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}
										>
											<MDBIcon icon="assistive-listening-systems" /> Flexible&nbsp;Personal
										</h2>
										<h2
											className="h2-responsive"
											style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}
										>
											Care at Home
										</h2>
									</strong>
									<MDBBtn href="/insurance" gradient="purple" size="lg">
										<MDBIcon icon="briefcase-medical" size="lg" /> SEE COVERAGE
									</MDBBtn>
								</MDBCard>
							</MDBAnimation>
						</MDBMask>
					</MDBView>
				</MDBCarouselItem>

				<MDBCarouselItem itemId="3">
					<MDBView>
						<img className="d-block w-100" src={AdultCare} alt="Third slide" />
						<MDBMask overlay="blue-grey-light" className="flex-center">
							<MDBAnimation type="bounceIn" duration="3s" infinite>
								<MDBCard
									style={{
										backgroundColor: 'rgba(20,82,148,0.6)',
										borderRadius: 10
									}}
								>
									<strong>
										<h2
											className="h2-responsive"
											style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}
										>
											<MDBIcon icon="briefcase" />
											Start a New Career&nbsp;
										</h2>
										<h2
											className="h2-responsive"
											style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}
										>
											with Midwest
										</h2>
									</strong>
									<MDBBtn href="/careers" color="danger" size="lg">
										<MDBIcon icon="briefcase" size="lg" /> APPLY NOW
									</MDBBtn>
								</MDBCard>
							</MDBAnimation>
						</MDBMask>
					</MDBView>
				</MDBCarouselItem>
			</MDBCarouselInner>
		</MDBCarousel>
	);
};

export default CarouselPage;
