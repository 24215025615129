import { ADD_TIMESHEETS, SET_TIMESHEETS, SET_DATATABLE } from '../actions/timeSheetsAction';

const initialState = {
	timeSheets: [],
	tableCol: [],
	TimeSheetTable: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_TIMESHEETS:
			return {
				timeSheets: action.timeSheets,
				tableCol: action.tableCols
			};
		case SET_DATATABLE:
			const datatable = action.timeSheets;
			return {
				...state,
				filesData: action.filesData,
				TimeSheetTable: action.TimeSheetTable,
				timeSheets: datatable
			};
		case ADD_TIMESHEETS:
			// const newTimeSheet = new TimeSheet(
			// 	// action.placeData.id.toString(),
			// 	action.placeData.title,
			// 	action.placeData.imageUri,
			// 	action.placeData.address,
			// 	action.placeData.lat,
			// 	action.placeData.lng
			// );

			return {
				// timeSheets: state.timeSheets.concat(newTimeSheet)
			};
		default:
			return state;
	}
};
