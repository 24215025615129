import React from 'react';
import {
	MDBCarousel,
	MDBCarouselInner,
	MDBCarouselItem,
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBTestimonial,
	MDBAvatar,
	MDBIcon
} from 'mdbreact';
import Characters_A from '../../../assets/images/Characters_A.jpg';
import Characters_B from '../../../assets/images/Characters_B.jpg';
import Characters_C from '../../../assets/images/Characters_C.jpg';
import Characters_D from '../../../assets/images/Characters_D.jpg';
import Yvette_Gibson from '../../../assets/images/black_female_user.jpeg';
import Alfonzo_Labon from '../../../assets/images/male_user_icon.jpeg';

const TestimonialsMultiPage = () => {
	return (
		<MDBContainer>
			<section className="text-center my-5">
				<h2 className="h1-responsive font-weight-bold my-5">Testimonials v.4</h2>
				<MDBCarousel activeItem={1} length={3} slide={true} showControls={true} multiItem testimonial>
					<MDBCarouselInner>
						<MDBRow>
							<MDBCarouselItem itemId="1">
								<MDBCol md="4">
									<MDBTestimonial>
										<MDBAvatar className="mx-auto">
											<img
												src={Yvette_Gibson}
												alt=""
												className="rounded-circle img-fluid"
												style={{ backgroundColor: 'red' }}
											/>
										</MDBAvatar>
										<h4 className="font-weight-bold mt-4">Yvette Gibson</h4>
										<h6 className="blue-text font-weight-bold my-3">PCA</h6>
										<p className="font-weight-normal" style={{ fontSize: 12 }}>
											<MDBIcon icon="quote-left" className="pr-2" />
											I am 17 and Midwest was my first job. It's $11 for everyone. It' s a great
											place to work for but they don't really provide many clients you have to
											find your own which is the hard part.
										</p>
										<div className="grey-text">
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
										</div>
									</MDBTestimonial>
								</MDBCol>
								<MDBCol md="4" className="clearfix d-none d-md-block">
									<MDBTestimonial>
										<MDBAvatar className="mx-auto">
											<img src={Characters_A} alt="" className="rounded-circle img-fluid" />
										</MDBAvatar>
										<h4 className="font-weight-bold mt-4">Alfonzo Labon</h4>
										<p className="font-weight-normal">
											<MDBIcon icon="quote-left" className="pr-2" />
											Midwest is the best, health care place to go. I love everything about it.
										</p>
										<div className="grey-text">
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
										</div>
									</MDBTestimonial>
								</MDBCol>
								<MDBCol md="4" className="clearfix d-none d-md-block">
									<MDBTestimonial>
										<MDBAvatar className="mx-auto">
											<img src={Characters_C} alt="" className="rounded-circle img-fluid" />
										</MDBAvatar>
										<h4 className="font-weight-bold mt-4">Abbey Clark</h4>
										<h6 className="blue-text font-weight-bold my-3">Photographer</h6>
										<p className="font-weight-normal">
											<MDBIcon icon="quote-left" className="pr-2" />
											Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam
											nihil molestiae.
										</p>
										<div className="grey-text">
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon far icon="star" />
										</div>
									</MDBTestimonial>
								</MDBCol>
							</MDBCarouselItem>
							<MDBCarouselItem itemId="2">
								<MDBCol md="4">
									<MDBTestimonial>
										<MDBAvatar className="mx-auto">
											<img src={Characters_D} alt="" className="rounded-circle img-fluid" />
										</MDBAvatar>
										<h4 className="font-weight-bold mt-4">Blake Dabney</h4>
										<h6 className="blue-text font-weight-bold my-3">Web Designer</h6>
										<p className="font-weight-normal">
											<MDBIcon icon="quote-left" className="pr-2" />
											Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
											laboriosam.
										</p>
										<div className="grey-text">
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon far icon="star-half" />
										</div>
									</MDBTestimonial>
								</MDBCol>
								<MDBCol md="4" className="clearfix d-none d-md-block">
									<MDBTestimonial>
										<MDBAvatar className="mx-auto">
											<img src={Characters_A} alt="" className="rounded-circle img-fluid" />
										</MDBAvatar>
										<h4 className="font-weight-bold mt-4">Andrea Clay</h4>
										<h6 className="blue-text font-weight-bold my-3">Front-end developer</h6>
										<p className="font-weight-normal">
											<MDBIcon icon="quote-left" className="pr-2" />
											Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id
											officiis hic tenetur quae.
										</p>
										<div className="grey-text">
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
										</div>
									</MDBTestimonial>
								</MDBCol>
								<MDBCol md="4" className="clearfix d-none d-md-block">
									<MDBTestimonial>
										<MDBAvatar className="mx-auto">
											<img src={Characters_B} alt="" className="rounded-circle img-fluid" />
										</MDBAvatar>
										<h4 className="font-weight-bold mt-4">Cami Gosse</h4>
										<h6 className="blue-text font-weight-bold my-3">Phtographer</h6>
										<p className="font-weight-normal">
											<MDBIcon icon="quote-left" className="pr-2" />
											At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
											praesentium.
										</p>
										<div className="grey-text">
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon far icon="star" />
										</div>
									</MDBTestimonial>
								</MDBCol>
							</MDBCarouselItem>
							<MDBCarouselItem itemId="3">
								<MDBCol md="4">
									<MDBTestimonial>
										<MDBAvatar className="mx-auto">
											<img src={Characters_C} alt="" className="rounded-circle img-fluid" />
										</MDBAvatar>
										<h4 className="font-weight-bold mt-4">Bobby Haley</h4>
										<h6 className="blue-text font-weight-bold my-3">Web Developer</h6>
										<p className="font-weight-normal">
											<MDBIcon icon="quote-left" className="pr-2" />
											Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id
											officiis hic tenetur quae.
										</p>
										<div className="grey-text">
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
										</div>
									</MDBTestimonial>
								</MDBCol>
								<MDBCol md="4" className="clearfix d-none d-md-block">
									<MDBTestimonial>
										<MDBAvatar className="mx-auto">
											<img src={Characters_D} alt="" className="rounded-circle img-fluid" />
										</MDBAvatar>
										<h4 className="font-weight-bold mt-4">Elisa Janson</h4>
										<h6 className="blue-text font-weight-bold my-3">Marketer</h6>
										<p className="font-weight-normal">
											<MDBIcon icon="quote-left" className="pr-2" />
											At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
											praesentium.
										</p>
										<div className="grey-text">
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon far icon="star-half" />
										</div>
									</MDBTestimonial>
								</MDBCol>
								<MDBCol md="4" className="clearfix d-none d-md-block">
									<MDBTestimonial>
										<MDBAvatar className="mx-auto">
											<img src={Characters_A} alt="" className="rounded-circle img-fluid" />
										</MDBAvatar>
										<h4 className="font-weight-bold mt-4">Rob Jacobs</h4>
										<h6 className="blue-text font-weight-bold my-3">Front-end developer</h6>
										<p className="font-weight-normal">
											<MDBIcon icon="quote-left" className="pr-2" />
											Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
											laboriosam.
										</p>
										<div className="grey-text">
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon icon="star" />
											<MDBIcon far icon="star" />
										</div>
									</MDBTestimonial>
								</MDBCol>
							</MDBCarouselItem>
						</MDBRow>
					</MDBCarouselInner>
				</MDBCarousel>
			</section>
		</MDBContainer>
	);
};

export default TestimonialsMultiPage;
