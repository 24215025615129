import React from 'react';
import {
    MDBContainer,
    MDBCardHeader,
    MDBRow,
    MDBCol,
    MDBCardBody,
    MDBInput,
    MDBIcon,
    MDBBtn,
    MDBJumbotron,
    MDBCardTitle
} from 'mdbreact';

import Nav from '../Nav';
import Footer from '../Footer';
import Logo from '../../../assets/images/healthLogo.png';
import Collapse from './CollapsePage2';
import Contact from './Message';
import './Insurance.css';

class Insurance extends React.Component {
    state = {
        collapseID: 'collapse1',
        windowWidth: 0,
        breakWidth: 1400
    };

    toggleCollapse = (collapseID) => () =>
        this.setState((prevState) => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ''
        }));

    render() {
        const dynamicLeftPadding = {
            paddingLeft: this.state.windowWidth > this.state.breakWidth ? '240px' : '0'
        };
        return (
            <React.Fragment>
                <div id='insurance'>
                    <div className='flexible-content mdb-skin'>
                        <Nav />
                    </div>

                    <main style={{ margin: '3.5rem 10% 5rem ' }}>
                        <br />
                        <MDBContainer>
                            <section id='author'>
                                <MDBJumbotron className='p-5 text-center text-md-left author-box'>
                                    <MDBCardHeader className='form-header blue-gradient rounded'>
                                        <h2 className='my-3'>
                                            <MDBIcon fab icon='servicestack' /> INSURANCE WE ACCEPT
                                        </h2>
                                    </MDBCardHeader>
                                    <MDBRow>
                                        <MDBCol size='12' md='2' className='mb-md-0 mb-4'>
                                            <img
                                                src={Logo}
                                                className='img-fluid'
                                                alt=''
                                                style={{
                                                    width: '1000px',
                                                    height: '100px',
                                                    border: '1px solid black',
                                                    borderRadius: 10
                                                }}
                                            />
                                        </MDBCol>
                                        <MDBCol size='12' md='10'>
                                            <h5>
                                                We accept state funded medical insurance with this partnership many
                                                insurance plans are eligible. If you do not see your insurance company
                                                listed please contact us, policies may update or change quarterly.
                                            </h5>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBContainer className='md-accordion mt-5'>
                                        <Collapse />
                                    </MDBContainer>
                                </MDBJumbotron>
                            </section>
                        </MDBContainer>
                    </main>
                    <main style={{ margin: '0rem 0% 0rem', backgroundColor: 'rgba(20,82,148,1)' }}>
                        <MDBContainer>
                            <section id='recent'>
                                <MDBCardBody>
                                    <MDBCardTitle className='h1 white-text font-weight-bold text-center'>
                                        Are you Interested in Our Services
                                        <hr className='my-5' style={{ borderColor: 'purple', borderWidth: '2px' }} />
                                    </MDBCardTitle>
                                    <MDBRow>
                                        <MDBCol>
                                            <div style={{ alignItems: 'left', color: 'white' }}>
                                                <h3>Steps Needed to Begin Service</h3>
                                                <MDBInput
                                                    label='State funded Medical Insurance (M.A)'
                                                    filled
                                                    type='checkbox'
                                                    id='checkbox1'
                                                />

                                                {/* Filled-in checked */}
                                                <MDBInput
                                                    label='PCA Assessment (From Certified Public Health Nurse)'
                                                    filled
                                                    checked
                                                    type='checkbox'
                                                    id='checkbox2'
                                                />

                                                <MDBInput
                                                    label='Do you already have a PCA in mind? (If not we have plenty)'
                                                    filled
                                                    type='checkbox'
                                                    id='checkbox3'
                                                />
                                            </div>
                                        </MDBCol>
                                        <MDBCol>
                                            <div className='pt-2'>
                                                <MDBBtn color='purple' className='waves-effect'>
                                                    Join <MDBIcon far icon='gem' />
                                                </MDBBtn>
                                                <MDBBtn outline color='white' className='waves-effect'>
                                                    Download <MDBIcon icon='download' />
                                                </MDBBtn>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </section>
                        </MDBContainer>
                    </main>
                    <main
                        style={{
                            margin: '10rem 6% 8rem'
                        }}
                    >
                        <Contact />
                    </main>
                </div>
                {/* <Footer
                    style={{ ...dynamicLeftPadding, position: 'fixed', width: '100%' }}
                    className='d-none d-lg-block'
                /> */}
                <Footer style={{ ...dynamicLeftPadding, width: '100%', marginTop: 50 }} className='d-none d-lg-block' />
            </React.Fragment>
        );
    }
}

export default Insurance;
