import React from 'react';
import Lightbox from 'react-image-lightbox';
import { MDBContainer, MDBRow, MDBCol, MDBMask, MDBView, MDBBtn } from 'mdbreact';
import './About.css';
import Nav from '../Nav';
import Footer from '../Footer';
import Timeline from './TimelinePage';
import Work_A from '../../../assets/images/Work_A.jpg';
import Work_B from '../../../assets/images/Work_B.jpg';
import Work_C from '../../../assets/images/Work_C.jpg';
import AboutCover from '../../../assets/images/about.png';

const images = [ Work_B, Work_A, Work_C ];
const smallImages = [ Work_B, Work_A, Work_C ];

class About extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapseID: '',
			photoIndex: 0,
			isOpen: false,
			windowWidth: 0,
			breakWidth: 1400
		};
	}

	toggleCollapse = (collapseID) => () =>
		this.setState((prevState) => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : ''
		}));

	render() {
		const dynamicLeftPadding = {
			paddingLeft: this.state.windowWidth > this.state.breakWidth ? '240px' : '0'
		};
		const { photoIndex, isOpen } = this.state;
		return (
			<React.Fragment>
				<div id="about">
					<Nav toggle={this.state.windowWidth < this.state.breakWidth} />
					<section id="home">
						<MDBView src={AboutCover}>
							<MDBMask
								overlay="stylish-strong"
								className="d-flex justify-content-centr align-items-center"
							>
								<MDBContainer
									className="h-100 d-flex justify-content-center align-items-center"
									style={{ fontWeight: 'bold' }}
								>
									<MDBRow>
										<MDBCol md="12" className="mt-5 mx-auto text-center">
											<h1 className="display-3 white-text mb-5">
												<strong className="white-text font-weight-bold">ABOUT US</strong>
											</h1>
											<h4 className="text-uppercase white-text mb-5 font-weight-bold">
												<strong className="white-text font-weight-bold">
													MHHC Timeline &amp; History
												</strong>
											</h4>
											<MDBBtn color="blue" size="lg">
												portfolio
											</MDBBtn>
											<MDBBtn color="blue" size="lg">
												About US
											</MDBBtn>
										</MDBCol>
									</MDBRow>
								</MDBContainer>
							</MDBMask>
						</MDBView>
					</section>
					<MDBContainer
						style={{
							marginTop: 20,
							backgroundColor: 'rgba(158, 158, 158, 0.5)',
							borderRadius: 10,
							paddingLeft: 35,
							paddingRight: 35,
							paddingBottom: 35,
							paddingTop: 10,
							borderStyle: 'solid',
							borderWidth: 1,
							borderColor: 'rgba(20,82,148,1)'
						}}
					>
						<section id="work">
							<h1 className="text-center my-5 h1">Our work</h1>
							<p className="text-center mb-5 w-responsive mx-auto" style={{ fontWeight: 'bold' }}>
								Established in 2002, MHHC is a Personal Care Provider Organization (PCPO) offering
								Personal Care Assistance (PCA) and Waiver Services through the Minnesota Medical
								Assistance Program.
							</p>

							<div className="mdb-lightbox">
								<MDBRow>
									<MDBCol md="4">
										<figure>
											<img
												src={smallImages[0]}
												alt="The pretty one talks"
												className="img-fluid"
												onClick={() => this.setState({ photoIndex: 0, isOpen: true })}
											/>
										</figure>
									</MDBCol>
									<MDBCol md="4">
										<figure>
											<img
												src={smallImages[1]}
												alt="Our office looks like school"
												className="img-fluid"
												onClick={() => this.setState({ photoIndex: 1, isOpen: true })}
											/>
										</figure>
									</MDBCol>
									<MDBCol md="4">
										<figure>
											<img
												src={smallImages[2]}
												alt="Best gear does not equal best devs"
												className="img-fluid"
												onClick={() => this.setState({ photoIndex: 2, isOpen: true })}
											/>
										</figure>
									</MDBCol>
								</MDBRow>
							</div>
							{isOpen && (
								<Lightbox
									mainSrc={images[photoIndex]}
									nextSrc={images[(photoIndex + 1) % images.length]}
									prevSrc={images[(photoIndex + images.length - 1) % images.length]}
									imageTitle={photoIndex + 1 + '/' + images.length}
									onCloseRequest={() => this.setState({ isOpen: false })}
									onMovePrevRequest={() =>
										this.setState({
											photoIndex: (photoIndex + images.length - 1) % images.length
										})}
									onMoveNextRequest={() =>
										this.setState({
											photoIndex: (photoIndex + 1) % images.length
										})}
								/>
							)}
						</section>
					</MDBContainer>
					<MDBContainer>
						<hr
							className="my-5"
							style={{ backgroundColor: 'rgba(158, 158, 158, 0.5)', borderWidth: '3px' }}
						/>
						<Timeline style={{ marginBottom: 0 }} />
					</MDBContainer>

					<Footer style={{ ...dynamicLeftPadding, width: '100%' }} className="d-none d-lg-block" />
				</div>
			</React.Fragment>
		);
	}
}

export default About;
