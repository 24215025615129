import React, { Component } from 'react';
import {
    MDBSideNavLink,
    MDBIcon,
    MDBSideNavCatV5,
    MDBSideNavNavV5,
    MDBSideNavV5
    //  MDBSideNavLinkV5
} from 'mdbreact';
import Place_holder from '../../assets/images/Portrait_Placeholder.png';

// Place_holdert { BrowserRouter as Router } from 'react-router-dom';

class AdminSideNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShown: false
        };
        this.showHover = this.showHover.bind(this);
        this.HideHover = this.HideHover.bind(this);
    }

    rSNL(to, text) {
        return (
            <MDBSideNavLink to={to} onClick={this.props.onLinkClick}>
                {text}
            </MDBSideNavLink>
        );
    }
    showHover() {
        this.setState({
            isShown: false
        });
    }
    HideHover() {
        this.setState({
            isShown: false
        });
    }
    render() {
        const { onLinkClick } = this.props;

        return (
            <div className='white-skin'>
                <MDBSideNavV5
                    slim
                    fixed
                    side
                    triggerOpening={this.props.triggerOpening}
                    breakWidth={this.props.breakWidth}
                    id='slim-1'
                    style={{ transition: 'padding-left .3s' }}
                    className='side-nav-light-v5'
                >
                    <li>
                        <div className='logo-wrapper-v5 sn-ad-avatar-wrapper-v5'>
                            <a href='#!'>
                                <img alt='' src={Place_holder} className='rounded-circle' />
                                <span className='text-black-50'>USER-NAME</span>
                                {/* <span className="d-none d-md-inline">{Username}</span> */}
                            </a>
                        </div>
                    </li>
                    {/* <MDBIcon icon="cart-arrow-down" /> */}

                    <MDBSideNavNavV5>
                        <MDBSideNavLink topLevel to='/adminHome' onClick={onLinkClick}>
                            <MDBIcon icon='home mr-2' size='md' style={{ marginLeft: 5 }} />
                        </MDBSideNavLink>

                        <MDBSideNavCatV5 name='UploadFiles' id='uploadfiles' icon='upload' iconSize='md'>
                            {this.rSNL('/adminUpload', 'FILE UPLOADS')}
                            {this.rSNL('/timesheets-preview', 'TIMESHEET PREVIEWS')}
                        </MDBSideNavCatV5>

                        {/* <MDBSideNavCatV5 name='Orders' id='orders' icon='cart-arrow-down' iconSize='md'>
                            {this.rSNL('/invoice-page', 'Invoice-Page')}
                            {this.rSNL('/customers-page', 'Customers-Page')}
                            {this.rSNL('/support-page', 'Support-Page')}
                        </MDBSideNavCatV5> */}
                        {/* <MDBSideNavCatV5 name="Contact me" id="contact-me" icon="envelope" iconSize="md">
							<MDBSideNavLinkV5>FAQ</MDBSideNavLinkV5>
							<MDBSideNavLinkV5>Write a message</MDBSideNavLinkV5>
						</MDBSideNavCatV5> */}

                        <MDBIcon
                            icon='angle-right'
                            size='lg'
                            pull='right'
                            style={{ marginLeft: 20, marginTop: 15, color: '#00C851' }}
                        />
                    </MDBSideNavNavV5>
                </MDBSideNavV5>
            </div>
        );
    }
}
export default AdminSideNavigation;
