import React from 'react';
import { MDBTimeline, MDBTimelineStep, MDBIcon, MDBContainer } from 'mdbreact';
import SectionContainer from '../../sectionContainer';
import OldBuilding from '../../../assets/images/OldBuilding.jpeg';
import RightSide from '../../../assets/images/rightSide.jpg';
import Managment from '../../../assets/images/Managment.jpg';
import LogoBlue from '../../../assets/images/LogoBlue2.jpg';
import Covid19 from '../../../assets/images/covid19.jpg';

const TimelinePage = () => {
    return (
        <MDBContainer>
            <SectionContainer
                tag='section'
                className='p-5'
                noBorder
                style={{
                    backgroundColor: 'rgba(158, 158, 158, 0.1)',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: 'rgba(20,82,148,1)',
                    borderRadius: 10,
                    padding: 0,
                    margin: 0
                }}
            >
                <MDBTimeline>
                    <MDBTimelineStep colorful hoverable color='blue' label='1'>
                        <img src={OldBuilding} className='img-fluid' alt='' />
                        <h4 className='font-weight-bold p-4 mb-0'>MHHC FOUNDING</h4>
                        <p className='text-muted px-4 mb-0'>
                            <MDBIcon icon='clock' /> 2000-2005
                        </p>
                        <p className='mb-0 p-4'>
                            MHHC was founded in 2000 by Mohamud Hassan with the vision to provide quality care for
                            patients from the compfort of their home.
                        </p>
                    </MDBTimelineStep>
                    <MDBTimelineStep colorful inverted hoverable color='blue' label='2'>
                        <img src={Managment} className='img-fluid' alt='' />
                        <h4 className='font-weight-bold p-4 mb-0'>Mangament Changes</h4>
                        <p className='text-muted px-4 mb-0'>
                            <MDBIcon icon='clock' /> 2006-2010
                        </p>
                        <p className='mb-0 p-4'>
                            Bashir and Rose take over key managment position and bring in a winfall of growth and scale
                            eventhrough the 2008 crisis. MHHC expandes into other states in the midwest cementing it's
                            role as major player in healthcare industry.
                        </p>
                    </MDBTimelineStep>
                    <MDBTimelineStep colorful hoverable color='blue' label='3'>
                        <img src={RightSide} className='img-fluid' alt='' />
                        <h4 className='font-weight-bold p-4 mb-0'>MHHC Changes Location</h4>
                        <p className='text-muted px-4 mb-0'>
                            <MDBIcon icon='clock' /> 2011-2015
                        </p>
                        <p className='mb-0 p-4'>
                            MHHC moves to the hostoric 2445 park avenue building to fit the growing staff numbers. The
                            building serves as phyiscal symbol of midwest managment philisophy of keeping one eye on the
                            past looking to learn from its mistakes and another eye on the future so to meet the demands
                            of the tomorrows healthcare Market.
                        </p>
                    </MDBTimelineStep>
                    <MDBTimelineStep colorful inverted hoverable color='blue' label='4'>
                        <img src={LogoBlue} className='img-fluid' alt='' />
                        <h4 className='font-weight-bold p-4 mb-0'>MHHC Service Expansion</h4>
                        <p className='text-muted px-4 mb-0'>
                            <MDBIcon icon='clock' /> 2016-2020
                        </p>
                        <p className='mb-0 p-4'>
                            MHHC expandes its services in healthcare industry branched out into providing HomeMaking,
                            Companion Services, and Hospice Care.
                        </p>
                    </MDBTimelineStep>
                    <MDBTimelineStep colorful hoverable color='blue' label='5'>
                        <img src={Covid19} className='img-fluid' alt='' />
                        <h4 className='font-weight-bold p-4 mb-0'>Global Epedemic</h4>
                        <p className='text-muted px-4 mb-0'>
                            <MDBIcon icon='clock' /> 2020
                        </p>
                        <p className='mb-0 p-4'>
                            Global Epidemic ravages the globe and we are working hard to adapt our operational,
                            technological, and business practices not only to survive but thrive.
                        </p>
                    </MDBTimelineStep>
                </MDBTimeline>
            </SectionContainer>
        </MDBContainer>
    );
};

export default TimelinePage;
