import React from 'react';
import './Contact.css';
import Nav from '../Nav';
import Footer from '../Footer';
import Info from './info';

class Register extends React.Component {
	state = {
		collapseID: ''
	};

	toggleCollapse = (collapseID) => () =>
		this.setState((prevState) => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : ''
		}));

	render() {
		const dynamicLeftPadding = {
			// paddingLeft: this.state.windowWidth > this.state.breakWidth ? '240px' : '0'
		};

		return (
			<div>
				<div />
				<Nav />
				<div id="contact">
					<main
						style={{
							margin: '10rem 6% 10rem'
						}}
					>
						<Info />
					</main>
					<Footer
						style={{ ...dynamicLeftPadding, position: 'fixed', width: '100%' }}
						className="d-none d-lg-block"
					/>
				</div>
			</div>
		);
	}
}

export default Register;
