import { AUTH_START, AUTH_SUCCESS, AUTH_FAIL, AUTH_LOGOUT, SET_AUTH_REDIRECT_PATH } from '../actions/authAction';

const initialState = {
	token: null,
	userId: null,
	error: null,
	role: null,
	username: null,
	loading: false,
	authRedirectPath: '/'
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_START:
			return {
				...state,
				error: null,
				loading: true
			};
		case AUTH_SUCCESS:
			return {
				...state,
				token: action.token,
				userId: action.userId,
				error: null,
				loading: false,
				role: action.role,
				username: action.username
			};
		case AUTH_FAIL:
			return {
				...state,
				error: action.error,
				loading: false
			};
		case AUTH_LOGOUT:
			return {
				...initialState
			};
		case SET_AUTH_REDIRECT_PATH:
			return {
				...state,
				authRedirectPath: action.path
			};
		default:
			return state;
	}
};

export default reducer;
