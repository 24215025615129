import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import * as authActions from '../../store/actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import {
	MDBNavbar,
	MDBNavbarBrand,
	MDBNavbarNav,
	MDBNavItem,
	MDBNavLink,
	MDBIcon,
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBBadge
} from 'mdbreact';

const AdminTopNavigation = (props) => {
	// const [ collapse, collapsed ] = useState(false);
	const [ dropdownOpen, SetdropdownOpen ] = useState(false);
	const dispatch = useDispatch();
	const Username = useSelector((state) => state.auth.username);
	// const onClick = () => collapsed(!collapse);
	const toggle = () => SetdropdownOpen(!dropdownOpen);
	const handleToggleClickA = () => {
		props.onSideNavToggleClick();
		console.log('toggle', toggle);
	};
	const navStyle = {
		paddingLeft: props.toggle ? '16px' : '240px',
		transition: 'padding-left .3s'
	};

	return (
		<Router>
			<MDBNavbar className="flexible-MDBNavbar" light expand="md" fixed="top" style={{ zIndex: 3 }}>
				<div
					onClick={handleToggleClickA}
					key="sideNavToggleA"
					style={{
						lineHeight: '32px',
						marginleft: '1em',
						verticalAlign: 'middle',
						cursor: 'pointer'
					}}
				>
					<MDBIcon icon="bars" color="white" size="lg" />
				</div>
				<MDBNavbarBrand href="/" style={navStyle}>
					<strong>{props.routeName}</strong>
				</MDBNavbarBrand>
				<MDBNavbarNav expand="sm" right style={{ flexDirection: 'row' }}>
					<MDBDropdown>
						<MDBDropdownToggle nav caret>
							<MDBBadge color="red" className="mr-2">
								3
							</MDBBadge>
							<MDBIcon icon="bell" /> <span className="d-none d-md-inline">Notifications</span>
						</MDBDropdownToggle>
						<MDBDropdownMenu right style={{ minWidth: '400px' }}>
							<MDBDropdownItem href="#!">
								<MDBIcon icon="money-bill-alt" className="mr-2" />
								New order received
								<span className="float-right">
									<MDBIcon icon="clock" /> 13 min
								</span>
							</MDBDropdownItem>
							<MDBDropdownItem href="#!">
								<MDBIcon icon="money-bill-alt" className="mr-2" />
								New order received
								<span className="float-right">
									<MDBIcon icon="clock" /> 33 min
								</span>
							</MDBDropdownItem>
							<MDBDropdownItem href="#!">
								<MDBIcon icon="chart-line" className="mr-2" />
								Your campaign is about to end
								<span className="float-right">
									<MDBIcon icon="clock" /> 53 min
								</span>
							</MDBDropdownItem>
						</MDBDropdownMenu>
					</MDBDropdown>
					<MDBNavItem>
						<MDBNavLink to="#">
							<MDBIcon icon="envelope" />
							<span className="d-none d-md-inline ml-1">Contact</span>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink to="#">
							<MDBIcon icon="comments" />
							<span className="d-none d-md-inline ml-1">Support</span>
						</MDBNavLink>
					</MDBNavItem>
					<MDBDropdown>
						<MDBDropdownToggle nav caret>
							<MDBIcon icon="user" />
							<span className="d-none d-md-inline">{Username}</span>
						</MDBDropdownToggle>
						<MDBDropdownMenu right style={{ minWidth: '200px' }}>
							<MDBDropdownItem href="/">Home</MDBDropdownItem>
							<MDBDropdownItem href="#!">My Account</MDBDropdownItem>
							<MDBDropdownItem
								onClick={() => {
									dispatch(authActions.logout());
								}}
							>
								Log Out
							</MDBDropdownItem>
						</MDBDropdownMenu>
					</MDBDropdown>
				</MDBNavbarNav>
			</MDBNavbar>
		</Router>
	);
};

export default AdminTopNavigation;
