import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Icon from '@material-ui/core/Icon';
import { MDBContainer } from 'mdbreact';
import { connect } from 'react-redux';

class JobTable extends Component {
    tableRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            jobs: []
        };
        // this.deletejob = this.deletejob.bind(this);
    }
    componentDidMount() {
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + '/jobs/')
            .then((response) => {
                this.setState({ jobs: response.data });
            })
            .catch((error) => {});
    }

    // deletejob(id) {
    // 	axios.delete('https://mhhc-server.herokuapp.com/jobs/' + id).then((response) => {});

    // 	this.setState({
    // 		jobs: this.state.jobs.filter((el) => el._id !== id)
    // 	});
    // }

    jobList() {
        let columns = [];
        if (this.state.jobs.length > 0) {
            columns = Object.keys(this.state.jobs[0])
                .filter((column) => {
                    if (
                        column === '_id' ||
                        column === '__v' ||
                        column === 'Position_Summary' ||
                        column === 'Qualifactions' ||
                        column === 'Essentail_Functions'
                    ) {
                        return false;
                    }
                    return true;
                })
                .map((column) => {
                    if (column === 'StartDate') {
                        return {
                            title: column,
                            field: column,
                            type: 'date'
                        };
                    } else if (column === 'Salary') {
                        return {
                            title: column,
                            field: column,
                            type: 'currency'
                        };
                    } else {
                        return {
                            title: column,
                            field: column,
                            type: 'string'
                        };
                    }
                });
        }

        return (
            <MaterialTable
                tableRef={this.tableRef}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [ 10, 20, 30 ],
                    actionsColumnIndex: -1,
                    sorting: true,
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF',
                        textAlign: 'center',
                        width: '10px'
                    },
                    cellStyle: {
                        textAlign: 'center'
                    }
                }}
                columns={columns}
                data={this.state.jobs}
                actions={[
                    {
                        icon: 'launch',
                        tooltip: 'Click To Apply',
                        onClick: (event, rowData) => alert('You saved ' + rowData.name)
                    }
                ]}
                components={{
                    Action: (props) => (
                        <Button style={{ textTransform: 'none' }}>
                            {/* ...target="_blank"> */}
                            <Link to='/application'>
                                <Icon>launch</Icon>
                            </Link>
                        </Button>
                    )
                }}
                detailPanel={[
                    {
                        tooltip: 'Show Details',
                        render: (rowData) => {
                            return (
                                <div
                                    style={{
                                        fontSize: 14,
                                        color: 'white',
                                        backgroundColor: '#4B515D',
                                        fontWeight: 'bold',
                                        padding: 15
                                    }}
                                >
                                    <ul>
                                        <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Position Summary:</h4>
                                        <li>{rowData.Position_Summary}</li>
                                        <hr className='my-5' style={{ backgroundColor: 'white', borderWidth: '3px' }} />
                                        <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Qualifactions:</h4>
                                        <li>{rowData.Qualifactions}</li>

                                        <hr className='my-5' style={{ backgroundColor: 'white', borderWidth: '3px' }} />

                                        <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Essentail Functions</h4>
                                        <li>{rowData.Essentail_Functions}</li>
                                        <hr className='my-5' style={{ backgroundColor: 'white', borderWidth: '3px' }} />
                                        <p>
                                            To provide equal employment and advancement opportunities to all
                                            individuals, employment decisions at Accra will be based on merit,
                                            qualifications, and abilities. Accra does not discriminate in employment
                                            opportunities or practices based on race, color, creed, religion, sex,
                                            national origin, age, disability, veteran status, military service, union
                                            membership, marital status, familial status, sexual orientation, gender
                                            identity, status with regards to public assistance, genetic information or
                                            any other characteristic protected by law
                                        </p>
                                    </ul>
                                </div>
                            );
                        }
                    }
                ]}
                title=''
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                <MDBContainer>
                    <br />
                    <h1 className='text-center my-5 h1'>CURRENT OPEN/AVAILABLE ROLES</h1>
                    {this.jobList()}
                </MDBContainer>
            </React.Fragment>
        );
    }
}

// export default JobTable;
const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

export default connect(mapStateToProps)(JobTable);
