import React from 'react';
import { MDBJumbotron, MDBBtn, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBCardBody, MDBCardTitle } from 'mdbreact';
import Brochure from '../../../assets/files/Midwest.Health.Brochure.pdf';

const JumbotronPage = () => {
	return (
		<MDBContainer className=" text-center">
			<MDBRow>
				<MDBCol>
					<MDBJumbotron
						style={{
							borderRadius: 10
						}}
					>
						<MDBCardBody>
							<MDBCardTitle className="h1  font-weight-bold" style={{ color: 'rgba(20,82,148,0.75)' }}>
								{/* <MDBCardTitle className="h1 light-blue-text font-weight-bold"> */}
								OUR MISSION STATEMENT
							</MDBCardTitle>
							<h4>
								Midwest is dedicated to providing the highest degree of personal service in an
								economical, efficient, and professional manner. Our focus is to maximize the health,
								functionality, and quality of life of the individuals we serve.
							</h4>
							<hr className="my-4" style={{ borderColor: 'rgba(20,82,148,0.5)', borderWidth: '3px' }} />
							<div className="pt-2">
								<MDBBtn color="primary" href="/careers" className="waves-effect">
									Join Our Mission <MDBIcon far icon="gem" />
								</MDBBtn>
								<MDBBtn outline color="primary" className="waves-effect">
									<a href={Brochure} download>
										Download Brochure <MDBIcon icon="download" />
									</a>
								</MDBBtn>
							</div>
						</MDBCardBody>
					</MDBJumbotron>
				</MDBCol>
			</MDBRow>
		</MDBContainer>
	);
};

export default JumbotronPage;
