import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput, MDBIcon, MDBBtn, MDBCardHeader } from 'mdbreact';
import GoogleMapReact from 'google-map-react';
import axios from 'axios';

class Info extends Component {
	state = {
		name: '',
		message: '',
		email: '',
		subject: '',
		sent: false,
		buttonText: 'Send Message'
	};
	formSubmit = (e) => {
		e.preventDefault();

		this.setState({
			buttonText: '...sending'
		});

		let data = {
			name: this.state.name,
			email: this.state.email,
			subject: this.state.subject,
			message: this.state.message
		};

		axios
			.post('/api/emailRoutes', data)
			.then((res) => {
				this.setState({ sent: true }, this.resetForm());
			})
			.catch(() => {
				console.log('Message not sent');
			});
	};
	resetForm = () => {
		this.setState({
			name: '',
			message: '',
			email: '',
			subject: '',
			buttonText: 'Message Sent'
		});
	};
	render() {
		return (
			<div>
				<MDBCard
					style={{
						backgroundColor: '#fff',
						paddingLeft: 50,
						paddingRight: 50,
						paddingTop: 10,
						paddingBottom: 10,
						borderRadius: 30
					}}
				>
					<MDBCardHeader className="form-header blue-gradient rounded">
						<h1 className="my-3">
							<MDBIcon fab icon="servicestack" /> Contact us
						</h1>
					</MDBCardHeader>
					<MDBCardBody>
						<MDBRow>
							<MDBCol lg="5" className="lg-0 mb-4">
								<MDBCard>
									<MDBCardBody>
										<div className="form-header blue-gradient accent-1">
											<h3>
												<MDBIcon icon="envelope" /> Write to us:
											</h3>
										</div>

										<MDBInput
											icon="user"
											iconClass="grey-text"
											type="text"
											id="name"
											label="Your name"
											onChange={(e) => this.setState({ name: e.target.value })}
											value={this.state.name}
										/>
										<MDBInput
											icon="envelope"
											iconClass="grey-text"
											type="text"
											id="email"
											label="Your email"
											onChange={(e) => this.setState({ email: e.target.value })}
											value={this.state.email}
										/>
										<MDBInput
											icon="tag"
											iconClass="grey-text"
											type="text"
											id="subject"
											label="Your Subject"
											onChange={(e) => this.setState({ subject: e.target.value })}
											value={this.state.subject}
										/>
										<MDBInput
											type="textarea"
											icon="pencil-alt"
											iconClass="grey-text"
											id="message"
											label="Your message"
											onChange={(e) => this.setState({ message: e.target.value })}
											value={this.state.message}
											gradient="blue"
										/>
										<div className="text-center">
											<MDBBtn gradient="blue" onClick={(e) => this.formSubmit(e)} rounded>
												{this.state.buttonText}
											</MDBBtn>
										</div>
									</MDBCardBody>
								</MDBCard>
							</MDBCol>

							<MDBCol lg="7">
								<div style={{ height: '400px' }} className="z-depth-1-half map-container">
									<GoogleMapReact
										defaultCenter={{ lat: 44.95754, lng: -93.26442 }}
										defaultZoom={11}
									/>
								</div>
								<MDBRow className="text-center">
									<MDBCol md="4">
										<MDBBtn floating tag="a" gradient="blue" color="">
											<MDBIcon icon="map-marker-alt" />
										</MDBBtn>
										<address>
											<h6>
												<strong>
													2445 Park Avenue <br />South Minneapolis,{' '}
												</strong>
											</h6>
											<h6>
												<strong>MN 55404, United States</strong>
											</h6>
										</address>
									</MDBCol>

									<MDBCol md="4">
										<MDBBtn floating tag="a" gradient="blue" color="">
											<MDBIcon icon="phone" />
										</MDBBtn>
										<address>
											<a href="mailto:generalinfo@mhhcare.com">
												<h6>
													<strong>generalinfo@mhhcare.com</strong>
												</h6>
											</a>
										</address>
									</MDBCol>

									<MDBCol md="4">
										<MDBBtn floating tag="a" gradient="blue" color="">
											<MDBIcon icon="envelope" />
										</MDBBtn>
										<h6>
											<strong>(612) 343-3265</strong>
										</h6>
										<h6>
											<strong>Mon - Fri, 8:30am - 5:00pm</strong>
										</h6>
									</MDBCol>
								</MDBRow>
							</MDBCol>
						</MDBRow>
						<hr />
						<ul style={{ fontSize: 14, fontWeight: 'bold' }}>
							<li>
								We are located at 2445 Park Avenue South in Minneapolis, MN at the historical Anson
								Brooks mansion. With being built as a family home in 1907 you may guess that there are
								many stories that are associated with our building.
							</li>
							<li>
								It’s limestone exterior is the epitome of a Venetian Gothic home that in this day in age
								is very rare, Our location is minutes from downtown and has a plethora of small and
								family owned business nearby.
							</li>
						</ul>
					</MDBCardBody>
				</MDBCard>
			</div>
		);
	}
}

export default Info;
