import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import * as ordersActions from '../../../store/actions/orderAction';
import { MDBContainer, MDBRow, MDBCard, MDBCardBody, MDBIcon, MDBBtn, MDBTable, MDBSpinner } from 'mdbreact';

const Invoice = (props) => {
	const [ isLoading, setIsLoading ] = useState(false);

	const orders = useSelector((state) => state.orders.orders);
	const dispatch = useDispatch();

	useEffect(
		() => {
			setIsLoading(true);
			dispatch(ordersActions.fetchOrders()).then(() => {
				setIsLoading(false);
			});
		},
		[ dispatch ]
	);

	let currentDate = new Date();
	let startDate = moment(currentDate).format('MM-DD-YYYY');
	let endDate = moment(new Date(Date.parse(currentDate) + 12096e5)).format('MM-DD-YYYY');
	if (isLoading) {
		return (
			<div
				style={{
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<MDBSpinner size="large" color="rgba(20,82,148,1" />
			</div>
		);
	}

	if (orders.length === 0) {
		return (
			<div style={{ justifyContent: 'center', alignItems: 'center' }}>
				<p>No order found, maybe start ordering some products?</p>
			</div>
		);
	}
	return (
		<div id="invoice">
			<MDBContainer fluid>
				<section className="mb-4">
					<MDBCard>
						<MDBCardBody className="d-flex justify-content-between">
							<h4 className="h4-responsive mt-3">Invoice #124190</h4>
							<div>
								<MDBBtn color="" style={{ backgroundColor: '#fc685f' }}>
									Charge Insurance
								</MDBBtn>
								<MDBBtn color="primary">
									<MDBIcon icon="print" className="left" /> Send Invoice
								</MDBBtn>
							</div>
						</MDBCardBody>
					</MDBCard>
				</section>

				<section className="mb-4">
					<MDBCard>
						<MDBCardBody>
							<MDBRow>
								<div className="col-md-6 text-left">
									<p>
										<small>From:</small>
									</p>
									<p>
										<strong>Accord Medical Inc</strong>
									</p>
									<p>2445 Park Ave</p>
									<p>Minneapolis, MN 55404</p>
									<p>
										<strong>Invoice date:</strong>
										{/* {new Date(new Date().getTime() * 60 * 60 * 1000)} */}
										{startDate}
									</p>
									<p>
										<strong>Due date:</strong>
										{/* {new Date(new Date().getTime() + parseInt(168) * 60 * 60 * 1000)} */}
										{endDate}
									</p>
								</div>

								<div className="col-md-6 text-right">
									<h4 className="h4-responsive">
										<small>Invoice No.</small>
										<br />
										<strong>
											<span className="blue-text">#124190</span>
										</strong>
									</h4>
									<p>
										<small>To:</small>
									</p>
									<p>
										<strong>The Company, Inc</strong>
									</p>
									<p>1-245 East Russel Road</p>
									<p>Munger, MI 48747</p>
								</div>
							</MDBRow>
						</MDBCardBody>
					</MDBCard>
				</section>

				<section>
					<MDBCard>
						<MDBCardBody>
							<MDBTable responsive>
								<thead>
									<tr>
										<th>Item list</th>
										<th>Quantity</th>
										<th>Unit Price</th>
										<th>orderDate</th>
										<th>USER_ID</th>
										<th>Total price</th>
									</tr>
								</thead>
								<tbody>
									{orders.map((order) => {
										return (
											<tr key={order.id}>
												<td>{order.items[0].productTitle}</td>
												<td>{order.items[0].quantity}</td>
												<td>${order.items[0].productPrice}</td>
												<td>{order.date}</td>
												<td>{order.userId}</td>
												<td>${order.totalAmount}</td>
											</tr>
										);
									})}
								</tbody>
							</MDBTable>

							<ul className="list-unstyled text-right">
								<li>
									<strong>Sub Total:</strong>
									<span className="float-right ml-3">$755</span>
								</li>
								<li>
									<strong>TAX:</strong>
									<span className="float-right ml-3">$173,65</span>
								</li>
								<li>
									<strong>TOTAL:</strong>
									<span className="float-right ml-3">$755</span>
								</li>
							</ul>
						</MDBCardBody>
					</MDBCard>
				</section>
			</MDBContainer>
		</div>
	);
};

export default Invoice;
