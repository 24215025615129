import React, { useEffect, useState } from 'react';
import { MDBDataTable, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBSpinner } from 'mdbreact';
import { useSelector, useDispatch } from 'react-redux';
import { loadFiles } from '../../../store/actions/timeSheetsAction';
import SectionContainer from '../../sectionContainer';

const TimeSheetTable = (props) => {
    const dispatch = useDispatch();
    const Cols = useSelector((state) => state.timeSheets.tableCol);
    const Rows = useSelector((state) => state.timeSheets.timeSheets);
    const datatable = { columns: Cols, rows: Rows };
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(
        () => {
            setIsLoading(true);
            dispatch(loadFiles()).then(() => {
                setIsLoading(false);
            });
        },
        [ dispatch ]
    );
    if (isLoading) {
        return (
            <div
                style={{
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <MDBSpinner size='large' color='rgba(20,82,148,1' />
            </div>
        );
    }

    if (Cols.length === 0 && Rows.length === 0) {
        return (
            <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                <p>No timeSheets found, please contact MHHC's office?</p>
            </div>
        );
    }
    return (
        <MDBContainer className='mt-3'>
            <MDBRow className='py-3'>
                <MDBCol md='12'>
                    <SectionContainer noBorder>
                        <MDBCard
                            style={{ marginBottom: '0px', border: '2px solid rgba(20,82,148,1)', borderRadius: 5 }}
                        >
                            <MDBCardBody>
                                <MDBDataTable
                                    scrollX
                                    // scrollY
                                    striped
                                    bordered
                                    hover
                                    data={datatable}
                                    entriesOptions={[ 5, 20, 25 ]}
                                    entries={5}
                                    pagesAmount={4}
                                    // filter="timesheet"
                                />
                            </MDBCardBody>
                        </MDBCard>
                    </SectionContainer>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default TimeSheetTable;
