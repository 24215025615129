import React from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBView, MDBContainer } from 'mdbreact';

import GoogleMapReact from 'google-map-react';

const Google = (props) => {
	return (
		<div
			id="maps-google"
			style={{
				paddingBottom: 20,
				backgroundColor: 'rgba(158, 158, 158, 0.5)',
				borderRadius: 15
			}}
		>
			<MDBContainer fluid>
				<MDBRow>
					<MDBCol md="6">
						<MDBCard className="mt-5" style={{ backgroundColor: 'rgba(20,82,148,1)' }} narrow>
							<MDBView cascade className="peach-gradient-card-header mdb-color white">
								<strong style={{ textAlign: 'center' }}>
									<h2 style={{ fontWeight: 'bold' }}>Minneapolis Location </h2>
									<h5 style={{ fontWeight: 'bold' }}>
										2445 Park Avenue South Minneapolis, MN 55404 <br />
										Office: (612) 343-3265 <br />
										Fax: (612) 343-3267
									</h5>
								</strong>
							</MDBView>
							<MDBCardBody style={{ width: '100%', height: '400px' }} className="text-center">
								<GoogleMapReact defaultCenter={{ lat: 44.95754, lng: -93.26442 }} defaultZoom={11} />
							</MDBCardBody>
						</MDBCard>
					</MDBCol>

					<MDBCol md="6">
						<MDBCard className="mt-5" style={{ backgroundColor: 'rgba(20,82,148,1)' }} narrow>
							<MDBView cascade className="peach-gradient-card-header mdb-color white">
								<strong style={{ textAlign: 'center' }}>
									<h2 style={{ fontWeight: 'bold' }}>St. Cloud Location</h2>
									<h5 style={{ fontWeight: 'bold' }}>
										606 25th Avenue South, #103 St. Cloud, MN 56301 <br />
										Office: (320) 654-2552 <br />
										Fax: (320) 654-8044
									</h5>
								</strong>
							</MDBView>
							<MDBCardBody style={{ width: '100%', height: '400px' }} className="text-center">
								<GoogleMapReact defaultCenter={{ lat: 45.55101, lng: -94.18797 }} defaultZoom={11} />
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</div>
	);
};

export default Google;
