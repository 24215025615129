import React, { Component } from 'react';
import {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBCollapse,
    MDBCollapseHeader,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBCardHeader
} from 'mdbreact';
import './style.css';
class CollapsePage extends Component {
    state = {
        collapseID: 'collapse1'
    };

    toggleCollapse = (collapseID) => () =>
        this.setState((prevState) => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ''
        }));

    render() {
        // const { collapseID } = this.state;
        return (
            <div>
                <MDBCard
                    style={{
                        backgroundImage: `url(https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20%2814%29.jpg)`,
                        borderRadius: 30
                    }}
                >
                    <div
                        className='rgba-black-strong py-5 px-4'
                        style={{
                            borderRadius: 30
                            // backgroundColor: 'rgba(0, 0, 0, 0.7)'
                        }}
                    >
                        <MDBRow className='d-flex justify-content-center'>
                            <MDBCol md='10' xl='8'>
                                <MDBContainer className='accordion md-accordion accordion-5 '>
                                    <MDBCardHeader className='form-header blue-gradient rounded'>
                                        <h1 className='my-3'>
                                            <MDBIcon fab icon='servicestack' /> SERVICES
                                        </h1>
                                    </MDBCardHeader>
                                    <MDBCard className='mb-4'>
                                        <MDBCollapseHeader
                                            onClick={this.toggleCollapse('collapse1')}
                                            className='p-0 z-depth-1'
                                            tag='h4'
                                            tagClassName='text-uppercase light-blue darken-4 white-text mb-0 d-flex justify-content-start align-items-center'
                                        >
                                            <div
                                                className='d-flex justify-content-center align-items-center mr-4'
                                                style={{ backgroundColor: '#fff', minWidth: '100px' }}
                                            >
                                                <MDBIcon
                                                    icon='assistive-listening-systems'
                                                    size='2x'
                                                    className='m-3 black-text'
                                                />
                                            </div>
                                            Personal Care Assistance
                                        </MDBCollapseHeader>

                                        <MDBCollapse id='collapse1' isOpen={this.state.collapseID}>
                                            <MDBCardBody className='rgba-black-light white-text z-depth-1'>
                                                <MDBRow className='my-4'>
                                                    <MDBCol md='8'>
                                                        <h5 className='font-weight-bold mb-3 white-text'>
                                                            PCA services include assistance with :
                                                        </h5>
                                                        <ul
                                                            className='font-weight-bold mb-3 white-text'
                                                            style={{ alignContent: 'Left', fontSize: 20 }}
                                                        >
                                                            <li>
                                                                <h6>
                                                                    Eating, Meal Preparation, Bathing, Grooming,
                                                                    Dressing, Transferring, Mobility (via driving,
                                                                    walking, wheelchair),
                                                                </h6>
                                                            </li>
                                                            <li>
                                                                <h6>
                                                                    Positioning, Toileting, Accompany to appointments,
                                                                    Light Housekeeping, Laundry, Some Health-Related
                                                                    Functions, Medicine Reminders
                                                                </h6>
                                                            </li>
                                                        </ul>
                                                        <h5 className='font-weight-bold mb-3 white-text'>
                                                            How To qualify?
                                                        </h5>
                                                        <ul
                                                            className='font-weight-bold mb-3 white-text'
                                                            style={{ alignContent: 'Left', fontSize: 20 }}
                                                        >
                                                            <li>
                                                                <h6>
                                                                    Assessed by an appointed professional by the County
                                                                    in which they live, or by their insurance carrier to
                                                                    determine if they qualify for this service.
                                                                </h6>
                                                            </li>
                                                        </ul>
                                                    </MDBCol>
                                                    <MDBCol md='4' className='mt-3 pt-2' />
                                                </MDBRow>
                                            </MDBCardBody>
                                        </MDBCollapse>
                                    </MDBCard>
                                </MDBContainer>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </MDBCard>
                <MDBCard
                    style={{
                        backgroundImage: `url(https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20%2814%29.jpg)`,
                        marginTop: '100px',
                        borderRadius: 30
                    }}
                >
                    <div
                        className='rgba-black-strong py-5 px-4'
                        style={{
                            borderRadius: 30
                        }}
                    >
                        <MDBRow className='d-flex justify-content-center'>
                            <MDBCol md='10' xl='8'>
                                <div className='accordion md-accordion accordion-5 '>
                                    <MDBCardHeader className='form-header blue-gradient rounded'>
                                        <h1 className='my-3'>
                                            <MDBIcon fab icon='servicestack' /> WAIVER SERVICES
                                        </h1>
                                    </MDBCardHeader>
                                    <div
                                        style={{
                                            border: '5px dashed black',
                                            marginBottom: '40px',
                                            padding: 15,
                                            borderRadius: 15

                                            // backgroundColor: '#0099CC'
                                        }}
                                    >
                                        <div>
                                            <h3 className='font-weight-bold' style={{ color: 'white' }}>
                                                How To qualify?
                                            </h3>
                                            <ul>
                                                <li
                                                    style={{
                                                        fontSize: '200%',
                                                        verticalAlign: 'middle',
                                                        color: 'white'
                                                    }}
                                                >
                                                    <h5 className='font-weight-bold white-text '>
                                                        Each home care waiver service must be approved through the
                                                        appropriate care coordinator or case manager.
                                                    </h5>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <MDBCard className='mb-4'>
                                        <MDBCollapseHeader
                                            onClick={this.toggleCollapse('collapse3')}
                                            className='p-0 z-depth-1'
                                            tag='h4'
                                            tagClassName='text-uppercase light-blue darken-4 white-text mb-0 d-flex justify-content-start align-items-center'
                                        >
                                            <div
                                                className='d-flex justify-content-center align-items-center mr-4'
                                                style={{ backgroundColor: '#fff', minWidth: '100px' }}
                                            >
                                                <MDBIcon icon='life-ring' size='2x' className='m-3 black-text' />
                                            </div>
                                            Personal Support
                                        </MDBCollapseHeader>

                                        <MDBCollapse id='collapse3' isOpen={this.state.collapseID}>
                                            <MDBCardBody className='rgba-black-light white-text z-depth-1'>
                                                <MDBRow className='my-4'>
                                                    <MDBCol md='8'>
                                                        <h5 className='font-weight-bold mb-3 white-text'>
                                                            Areas of support:
                                                        </h5>
                                                        <ul
                                                            className='font-weight-bold mb-3 white-text'
                                                            style={{ alignContent: 'Left', fontSize: 20 }}
                                                        >
                                                            <li>
                                                                <h6>Achieve his/her full potential</h6>
                                                            </li>
                                                            <li>
                                                                <h6>Increase his/her independence</h6>
                                                            </li>
                                                            <li>
                                                                <h6>
                                                                    Meet community inclusion goals that are important to
                                                                    and important for the recipient and based on an
                                                                    assessed need
                                                                </h6>
                                                            </li>
                                                        </ul>
                                                        <h5 className='font-weight-bold mb-3 white-text'>
                                                            How To qualify?
                                                        </h5>
                                                        <ul
                                                            className='font-weight-bold mb-3 white-text'
                                                            style={{ alignContent: 'Left', fontSize: 20 }}
                                                        >
                                                            <li>
                                                                <h6>
                                                                    Provides support in the recipient’s home or
                                                                    community.
                                                                </h6>
                                                            </li>
                                                        </ul>
                                                    </MDBCol>
                                                    <MDBCol md='4' className='mt-3 pt-2' />
                                                </MDBRow>
                                            </MDBCardBody>
                                        </MDBCollapse>
                                    </MDBCard>
                                    <MDBCard className='mb-4'>
                                        <MDBCollapseHeader
                                            onClick={this.toggleCollapse('collapse4')}
                                            className='p-0 z-depth-1'
                                            tag='h4'
                                            tagClassName='text-uppercase light-blue darken-4 white-text mb-0 d-flex justify-content-start align-items-center'
                                        >
                                            <div
                                                className='d-flex justify-content-center align-items-center mr-4'
                                                style={{ backgroundColor: '#fff', minWidth: '100px' }}
                                            >
                                                <MDBIcon icon='home' size='2x' className='m-3 black-text' />
                                            </div>
                                            In-home Respite Care
                                        </MDBCollapseHeader>

                                        <MDBCollapse id='collapse4' isOpen={this.state.collapseID}>
                                            <MDBCardBody className='rgba-black-light white-text z-depth-1'>
                                                <MDBRow className='my-4'>
                                                    <MDBCol md='8'>
                                                        <h5 className='font-weight-bold mb-3 white-text'>
                                                            Respite Care:
                                                        </h5>
                                                        <ul>
                                                            <li>
                                                                <h6>
                                                                    Is a service that allows a primary caregiver time
                                                                    away or a break from the responsibilities as a
                                                                    caregiver.
                                                                </h6>
                                                            </li>
                                                        </ul>
                                                    </MDBCol>
                                                    <MDBCol md='4' className='mt-3 pt-2' />
                                                </MDBRow>
                                            </MDBCardBody>
                                        </MDBCollapse>
                                    </MDBCard>
                                    <MDBCard className='mb-4'>
                                        <MDBCollapseHeader
                                            onClick={this.toggleCollapse('collapse5')}
                                            className='p-0 z-depth-1'
                                            tag='h4'
                                            tagClassName='text-uppercase light-blue darken-4 white-text mb-0 d-flex justify-content-start align-items-center'
                                        >
                                            <div
                                                className='d-flex justify-content-center align-items-center mr-4'
                                                style={{ backgroundColor: '#fff', minWidth: '100px' }}
                                            >
                                                <MDBIcon icon='broom' size='2x' className='m-3 black-text' />
                                            </div>
                                            Homemaker Services
                                        </MDBCollapseHeader>

                                        <MDBCollapse id='collapse5' isOpen={this.state.collapseID}>
                                            <MDBCardBody className='rgba-black-light white-text z-depth-1'>
                                                <MDBRow className='my-4'>
                                                    <MDBCol md='8'>
                                                        <h5 className='font-weight-bold mb-3 white-text'>
                                                            Homemaker/Cleaning includes:
                                                        </h5>
                                                        <ul
                                                            className='font-weight-bold mb-3 white-text'
                                                            style={{ alignContent: 'Left', fontSize: 20 }}
                                                        >
                                                            <li>
                                                                <h6> Housekeeping duties and laundry light</h6>
                                                            </li>
                                                            <li>
                                                                <h6>
                                                                    Homemaker with ADL’s includes bathing, grooming,
                                                                    eating, ambulating and worker is allowed to do home
                                                                    cleaning and laundry (modifier TG)
                                                                </h6>
                                                            </li>
                                                            <li>
                                                                <h6>
                                                                    Homemaker/Home Management includes laundry, meal
                                                                    preparation, shopping for food, clothing, and
                                                                    supplies, simple household repairs, arranging for
                                                                    transportation and worker is allowed to do home
                                                                    cleaning (modifier TF)
                                                                </h6>
                                                            </li>
                                                        </ul>
                                                    </MDBCol>
                                                    <MDBCol md='4' className='mt-3 pt-2' />
                                                </MDBRow>
                                            </MDBCardBody>
                                        </MDBCollapse>
                                    </MDBCard>
                                    <MDBCard className='mb-4'>
                                        <MDBCollapseHeader
                                            onClick={this.toggleCollapse('collapse6')}
                                            className='p-0 z-depth-1'
                                            tag='h4'
                                            tagClassName='text-uppercase light-blue darken-4 white-text mb-0 d-flex justify-content-start align-items-center'
                                        >
                                            <div
                                                className='d-flex justify-content-center align-items-center mr-4'
                                                style={{ backgroundColor: '#fff', minWidth: '100px' }}
                                            >
                                                <MDBIcon icon='tasks' size='2x' className='m-3 black-text' />
                                            </div>
                                            Chore Services
                                        </MDBCollapseHeader>

                                        <MDBCollapse id='collapse6' isOpen={this.state.collapseID}>
                                            <MDBCardBody className='rgba-black-light white-text z-depth-1'>
                                                <MDBRow className='my-4'>
                                                    <MDBCol md='8'>
                                                        <h5 className='font-weight-bold mb-3 white-text'>
                                                            Services include:
                                                        </h5>
                                                        <ul
                                                            className='font-weight-bold mb-3 white-text'
                                                            style={{ alignContent: 'Left', fontSize: 20 }}
                                                        >
                                                            <li>
                                                                <h6>Dumpster rental or refuse disposal</h6>
                                                            </li>
                                                            <li>
                                                                <h6>
                                                                    A limited amount of extermination and pest control
                                                                    treatments to alleviate pest problem
                                                                </h6>
                                                            </li>
                                                            <li>
                                                                <h6>
                                                                    General indoor and outdoor home maintenance (e.g.
                                                                    lawn care, snow removal)
                                                                </h6>
                                                            </li>
                                                        </ul>
                                                        <h5 className='font-weight-bold mb-3 white-text'>
                                                            How To qualify?
                                                        </h5>
                                                        <ul
                                                            className='font-weight-bold mb-3 white-text'
                                                            style={{ alignContent: 'Left', fontSize: 20 }}
                                                        >
                                                            <li>
                                                                <h6>
                                                                    Assistance provided to a person, or his primary
                                                                    caregiver, to help maintain a clean, sanitary and
                                                                    safe environment.
                                                                </h6>
                                                            </li>
                                                        </ul>
                                                    </MDBCol>
                                                    <MDBCol md='4' className='mt-3 pt-2' />
                                                </MDBRow>
                                            </MDBCardBody>
                                        </MDBCollapse>
                                    </MDBCard>
                                    <MDBCard className='mb-4'>
                                        <MDBCollapseHeader
                                            onClick={this.toggleCollapse('collapse7')}
                                            className='p-0 z-depth-1'
                                            tag='h4'
                                            tagClassName='text-uppercase light-blue darken-4 white-text mb-0 d-flex justify-content-start align-items-center'
                                        >
                                            <div
                                                className='d-flex justify-content-center align-items-center mr-4'
                                                style={{ backgroundColor: '#fff', minWidth: '100px' }}
                                            >
                                                <MDBIcon icon='bed' size='2x' className='m-3 black-text' />
                                            </div>
                                            Night Supervision
                                        </MDBCollapseHeader>

                                        <MDBCollapse id='collapse7' isOpen={this.state.collapseID}>
                                            <MDBCardBody className='rgba-black-light white-text z-depth-1'>
                                                <MDBRow className='my-4'>
                                                    <MDBCol md='8'>
                                                        <h5 className='font-weight-bold mb-3 white-text'>
                                                            Areas of service:
                                                        </h5>
                                                        <ul
                                                            className='font-weight-bold mb-3 white-text'
                                                            style={{ alignContent: 'Left', fontSize: 20 }}
                                                        >
                                                            <li>
                                                                <h6>
                                                                    Carrying out a recipient’s positive support
                                                                    programming and transition plans.
                                                                </h6>
                                                            </li>
                                                            <li>
                                                                <h6>
                                                                    Reinforcing independent living skills training and
                                                                    other skill development supports
                                                                </h6>
                                                            </li>
                                                            <li>
                                                                <h6>
                                                                    Assisting with instrumental activities of daily
                                                                    living (IADLs)
                                                                </h6>
                                                            </li>
                                                        </ul>
                                                        <h5 className='font-weight-bold mb-3 white-text'>
                                                            What is It?
                                                        </h5>
                                                        <ul
                                                            className='font-weight-bold mb-3 white-text'
                                                            style={{ alignContent: 'Left', fontSize: 20 }}
                                                        >
                                                            <li>
                                                                <h6>
                                                                    Overnight assistance and monitoring provided by an
                                                                    awake staff in the recipient’s own home.
                                                                </h6>
                                                            </li>
                                                        </ul>
                                                    </MDBCol>
                                                    <MDBCol md='4' className='mt-3 pt-2' />
                                                </MDBRow>
                                            </MDBCardBody>
                                        </MDBCollapse>
                                    </MDBCard>
                                    <MDBCard className='mb-4'>
                                        <MDBCollapseHeader
                                            onClick={this.toggleCollapse('collapse8')}
                                            className='p-0 z-depth-1'
                                            tag='h4'
                                            tagClassName='text-uppercase light-blue darken-4 white-text mb-0 d-flex justify-content-start align-items-center'
                                        >
                                            <div
                                                className='d-flex justify-content-center align-items-center mr-4'
                                                style={{ backgroundColor: '#fff', minWidth: '100px' }}
                                            >
                                                <MDBIcon icon='diagnoses' size='2x' className='m-3 black-text' />
                                            </div>
                                            ILS Training
                                        </MDBCollapseHeader>

                                        <MDBCollapse id='collapse8' isOpen={this.state.collapseID}>
                                            <MDBCardBody className='rgba-black-light white-text z-depth-1'>
                                                <MDBRow className='my-4'>
                                                    <MDBCol md='8'>
                                                        <h4 className='font-weight-bold mb-3 white-text'>
                                                            Areas of training:
                                                        </h4>
                                                        <ul
                                                            className='font-weight-bold mb-3 white-text'
                                                            style={{ alignContent: 'Left', fontSize: 20 }}
                                                        >
                                                            <li>
                                                                <h6>Communication skills</h6>
                                                            </li>
                                                            <li>
                                                                <h6>Community living and mobility</h6>
                                                            </li>
                                                            <li>
                                                                <h6>Interpersonal skills</h6>
                                                            </li>
                                                            <li>
                                                                <h6>Reduction/elimination of maladaptive behavior</h6>
                                                            </li>
                                                            <li>
                                                                <h6>Self-care</h6>
                                                            </li>
                                                            <li>
                                                                <h6>
                                                                    Sensory/motor development involved in acquiring
                                                                    functional skills.
                                                                </h6>
                                                            </li>
                                                        </ul>
                                                        <h5 className='font-weight-bold mb-3 white-text'>
                                                            What is It?
                                                        </h5>
                                                        <ul
                                                            className='font-weight-bold mb-3 white-text'
                                                            style={{ alignContent: 'Left', fontSize: 20 }}
                                                        >
                                                            <li>
                                                                <h6>
                                                                    Services that develop, maintain and improve the
                                                                    community-living skills of a recipient.
                                                                </h6>
                                                            </li>
                                                        </ul>
                                                    </MDBCol>
                                                    <MDBCol md='4' className='mt-3 pt-2' />
                                                </MDBRow>
                                            </MDBCardBody>
                                        </MDBCollapse>
                                    </MDBCard>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </MDBCard>
            </div>
        );
    }
}

export default CollapsePage;
