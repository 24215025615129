import React, { useState } from 'react';
// import { connect, useSelector, useDispatch } from 'react-redux';
import { useDispatch } from 'react-redux';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact';
import * as authActions from '../../store/actions/authAction';
import Nav from './Nav';
const ResetPassword = (props) => {
    const token = props.match.params.token;
    const [ inputs, setInputs ] = useState({});
    // const [ submitted, setSubmitted ] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('hit');
        if (inputs) {
            dispatch(authActions.createNewPass(inputs.password, token));
        }
    };
    return (
        // <input onChange={ev => setInputs({...inputs, email: ev.target.value})}/>
        <div>
            <Nav />
            <main style={{ margin: '15rem 6% 0rem' }}>
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md='6'>
                            <form onSubmit={handleSubmit}>
                                <p className='h5 text-center mb-4'>ResetPassword</p>
                                <div className='grey-text'>
                                    <MDBInput
                                        label='password'
                                        icon='lock'
                                        group
                                        type='password'
                                        validate
                                        required
                                        error='wrong'
                                        success='right'
                                        onChange={(ev) => setInputs({ ...inputs, password: ev.target.value })}
                                    />
                                    {/* <MDBInput label='Confirm password' icon='lock' group type='text' validate /> */}
                                </div>
                                <div className='text-center'>
                                    <MDBBtn type='submit'>Login</MDBBtn>
                                </div>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </main>
        </div>
    );
};

export default ResetPassword;
