import { SET_ORDERS } from '../actions/orderAction';

const initialState = {
	orders: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_ORDERS:
			return {
				orders: action.orders
			};
		// case ADD_ORDER:
		// 	const newOrder = {
		// 		id: action.orderData.id,
		// 		items: action.orderData.items,
		// 		totalAmount: action.orderData.amount,
		// 		date: action.orderData.date
		// 	};
		// 	return {
		// 		...state,
		// 		orders: state.orders.concat(newOrder)
		// 	};
		default:
			return state;
	}
};
